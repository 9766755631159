import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import DashboardLayout from './containers/DashboardLayout/Loadable';
import MainLayout from './containers/MainLayout/Loadable';
import LoginView from './containers/LoginView/Loadable';
import PasswordReset from './containers/PasswordReset/Loadable';
import CategoryEditView from './containers/CategoryEditView/Loadable';
import CategoryList from './containers/CategoryList/Loadable';
import GroupCharacteristicEditView from './containers/GroupCharacteristicEditView/Loadable';
import GroupCharacteristicList from './containers/GroupCharacteristicList/Loadable';
import SpecEditView from './containers/SpecEditView/Loadable';
import SpecList from './containers/SpecList/Loadable';
import ProductEditView from './containers/ProductEditView/Loadable';
import ProductList from './containers/ProductList/Loadable';
import MarkerEditView from './containers/MarkerEditView/Loadable';
import MarkerList from './containers/MarkerList/Loadable';
import WarehouseList from './containers/WarehouseList';
import WarehouseEditView from './containers/WarehouseEditView';
import QuickFilterList from './containers/QuickFilterList';
import QuickFilterEditView from './containers/QuickFilterEditView';
import BanksEditView from './containers/BanksEditView';
import BanksList from './containers/BanksList';
import InstallmentManagementList from './containers/InstallmentManagementList';
import InstallmentManagementEditView from './containers/InstallmentManagmentEditView';
import CreditTypesList from './containers/CreditTypesList';
import CreditTypeEditView from './containers/CreditTypeEditView';
import CreditOffersList from './containers/CreditOffersList';
import CreditOffersEditView from './containers/CreditOffersView';
import AdditionalServicesList from './containers/AdditionalServicesList';
import AdditionalServicesEditView from './containers/AdditionalServicesEditView';
import PromocodeSeriesList from './containers/PromocodeSeriesList';
import PromocodeSeriesEditView from './containers/PromocodeSeriesEditView';
import UnaccountedProductsList from './containers/UnaccountedProductsList';
import UnaccountedProductsEditView from './containers/UnaccountedProductsEditView';
import SpecOfferList from './containers/SpecOfferList';
import SpecOfferEditView from './containers/SpecOfferEditView';
import AccessoryRelationEditView from './containers/AccessoryRelationEditView';
import AccessoryRelationList from './containers/AccessoryRelationList';
import SpecOfferBannerList from './containers/SpecOfferBannerList';
import SpecOfferBannerEditView from './containers/SpecOfferBannerEditView';
import SpecOfferBannerTagList from './containers/SpecOfferBannerTagList';
import SpecOfferBannerTagEditView from './containers/SpecOfferBannerTagEditView';
import BannerManagementHomeList from './containers/BannerManagementHomeList';
import BannerManagementHomeEditView from './containers/BannerManagementHomeEditView';
import BannerManagementMenuList from './containers/BannerManagementMenuList';
import BannerManagementMenuEditView from './containers/BannerManagementMenuEditView';
import BannerManagementCatalogList from './containers/BannerManagementCatalogList';
import BannerManagementCatalogEditView from './containers/BannerManagementCatalogEditView';
import BannerManagementHeadList from './containers/BannerManagementHeadList';
import BannerManagementHeadEditView from './containers/BannerManagementHeadEditView';
import BenefitList from './containers/BenefitList';
import BenefitEditView from './containers/BenefitEditView';
import PopularGoodList from './containers/PopularGoodList';
import PopularGoodEditView from './containers/PopularGoodEditView';
import NewestList from './containers/NewestList';
import NewestEditView from './containers/NewestEditView';
import PopularCategoryList from './containers/PopularCategoryList';
import PopularCategoryEditView from './containers/PopularCategoryEditView';
import UniqueServiceList from './containers/UniqueServiceList';
import UniqueServiceEditView from './containers/UniqueServiceEditView';
import ServiceList from './containers/ServiceList';
import ServiceEditView from './containers/ServiceEditView';
import InCartList from './containers/InCartList';
import InCartEditView from './containers/InCartEditView';
import TopAndBottomMenuList from './containers/TopAndBottomMenuList';
import TopAndBottomEditView from './containers/TopAndBottomMenuEditView';
import CategoriesMenuList from './containers/CategoriesMenuList'
import CategoriesMenuEditView from './containers/CategoriesMenuEditView';
import ProductFeedbackList from './containers/ProductFeedbackList';
import ProductFeedbackEditView from './containers/ProductFeedbackEditView';
import ProductConsultationList from './containers/ProductConsultationList';
import ProductConsultationEditView from './containers/ProductConsultationEditView';
import ProductB2BList from './containers/ProductB2BList';
import ProductB2BEditView from './containers/ProductB2BEditView';
import PermissionAdminList from './containers/PermissionAdminList';
import PermissionClientList from './containers/PermissionClientList';
import RoleList from './containers/RoleList';
import RoleEditView from './containers/RoleEditView';
import FeedsEditView from './containers/FeedsEditView';
import FeedsList from './containers/FeedsList';
import OrderEditView from './containers/OrderEditView';
import OrderList from './containers/OrderList'
import UsersClientList from './containers/UsersClientList'
import UserClientEditView from './containers/UsersClientEditView';
import PaymentList from './containers/PaymentList';
import PaymentEditView from './containers/PaymentEditView';
import DeliveryList from './containers/DeliveryList';
import DeliveryEditView from './containers/DeliveryEditView';
import ContactList from './containers/ContactList';
import ContactEditView from './containers/ContactEditView';
import DomainList from './containers/DomainsList';
import DomainsEditView from './containers/DomainsEditView';
import EmailSubscription from './containers/EmailSubscriptionList';
import OrderDocumentList from './containers/OrderDocumentList';
import OrderDocumentEditView from './containers/OrderDocumentEditView';
import TemplateList from "./containers/TemplateList";
import TemplateEditView from "./containers/TemplateEditView";
import RedirectsList from "./containers/RedirectsList";
import RedirectEditView from "./containers/RedirectEditView";
import PageSettingsList from "./containers/PageSettingsList";
import PageSettingEditView from "./containers/PageSettingEditView";
import StatusesList from "./containers/StatusesList";
import StatusEditView from "./containers/StatusEditView";
import StatusChangeList from "./containers/StatusChangeList";
import StatusChangeEditView from "./containers/StatusChangeEditView";
import PurchaseList from "./containers/PurchaseList";
import DevicesList from "./containers/DevicesList";
import DevicesViewEdit from "./containers/DevicesEditView";
import SpecialistsEditView from "./containers/SpecialistsEditView";
import SpecialistsList from "./containers/SpecialistsList";
import TradeInList from "./containers/TradeInList";
import SubscribersList from "./containers/SubscribersList";
import SubscriberEditView from "./containers/SubscriberEditView";
import AdminsList from "./containers/AdminsList";
import AdminEditView from "./containers/AdminEditView";
import ReportsOrders from "./containers/ReportsOrders";
import ArticlesList from "./containers/ArticlesList";
import ArticleEditView from "./containers/ArticleEditView";
import BrandsList from "./containers/BrandsList";
import BrandEditView from "./containers/BrandEditView";
import checkPermission from "./lib/checkPermission";
import Dashboard from "./containers/Dashboard";
import MediaList from "./containers/MediaList";
import MediaEditView from "./containers/MediaEditView";
import ReviewsList from "./containers/ReviewsList";
import ReviewEditView from "./containers/ReviewEditView";
import TryAndBuyList from "./containers/TryAndBuyList";
import EasyProList from "./containers/EasyProList";
import HrDomainMaskList from "./containers/HrDomainMaskList";
import HrDomainMaskViewEdit from "./containers/HrDomainMaskEditView";
import BannerManagementCatalogHorizontalList from "./containers/BannerManagementCatalogHorizontalList";
import BannerManagementCatalogHorizontalEditView from "./containers/BannerManagementCatalogHorizontalEditView";
import PromocodeOfNonPurchasingActivityList from "./containers/PromocodeOfNonPurchasingActivityList";
import PromocodeOfNonPurchasingActivityEditView from "./containers/PromocodeOfNonPurchasingActivityEditView";
import CooperationB2BList from "./containers/CooperationB2BList";
import PromocodeRegisterList from "./containers/PromocodeRegisterList";
import PromocodeRegisterEditView from "./containers/PromocodeRegisterEditView";
import SortProductsList from "./containers/SortProductsList";
import SortSpecOffersList from "./containers/SortSpecOffersList";
import SortBannerHeaderList from "./containers/SortBannerHeaderList";
import SortBannerCatalogList from "./containers/SortBannerCatalogList";
import SortBannerMainPageList from "./containers/SortBannerMainPageList";
import SortBannerMenuList from "./containers/SortBannerMenuList";
import SortBannerCatalogHorizontalList from "./containers/SortBannerCatalogHorizontalList";
import SortCategoriesList from "./containers/SortCategoriesList";
import DocumentsList from "./containers/DocumentsList";
import SelectionsList from "./containers/SelectionsList";
import SelectionEditView from "./containers/SelectionEditView";
import DiscountPks from "./containers/DiscountPks";
import SectionEditView from "./containers/SectionEditView";
import SectionsList from "./containers/SectionsList";
import ClaimList from "./containers/ClaimList";
import PromocodeServiceCenterList from "./containers/PromocodeServiceCenterList";
import PromocodeServiceCentersEditView from "./containers/PromocodeServiceCentersEditView";
import SubscriberEvents from "./containers/SubscriberEvents";
import GalaxyCafeList from "./containers/GalaxyCafeList";
import SortBankPPList from "./containers/SortBanksPPList";


const routes = (isAuthenticated) => {
  const navigate = useNavigate();
  if(localStorage.followingLink && isAuthenticated) {
    navigate(localStorage.followingLink);
    localStorage.removeItem('followingLink')
  }
  return [
    {
      path: 'app/*',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [

        /* InputAllSearch */
        { path: 'categories', element: <CategoryList /> },
        { path: 'category/update/:id', element: <CategoryEditView /> },
        { path: 'category/not-found', element: <p>Category not found</p> },
        { path: 'category', element: <Navigate to="/app/categories" /> },

        /* Specification InputAllSearch */
        { path: 'group-characteristic', element: <GroupCharacteristicList /> },
        { path: 'group-characteristic/create', element: <GroupCharacteristicEditView /> },
        { path: 'group-characteristic/update/:id', element: <GroupCharacteristicEditView /> },
        { path: 'group-characteristic/not-found', element: <p>Group Characteristic not found</p> },
        { path: 'group-characteristic', element: <Navigate to="/app/group-characteristic" /> },

        /* Specifications */
        { path: 'specs', element: <SpecList /> },
        { path: 'spec/create', element: <SpecEditView /> },
        { path: 'spec/update/:id', element: <SpecEditView /> },
        { path: 'spec/not-found', element: <p>Specification not found</p> },
        { path: 'spec', element: <Navigate to="/app/specs" /> },

        /* Product */
        { path: 'products', element: <ProductList /> },
        { path: 'product/create', element: <ProductEditView /> },
        { path: 'product/update/:id', element: <ProductEditView /> },
        { path: 'product/not-found', element: <p>Product not found</p> },
        { path: 'product', element: <Navigate to="/app/products" /> },

        /* Marker */
        { path: 'markers', element: <MarkerList /> },
        { path: 'marker/create', element: <MarkerEditView /> },
        { path: 'marker/update/:id', element: <MarkerEditView /> },
        { path: 'marker/not-found', element: <p>Marker not found</p> },
        { path: 'marker', element: <Navigate to="/app/markers" /> },

        /* Warehouse */
        { path: 'warehouses', element: <WarehouseList /> },
        { path: 'warehouse/create', element: <WarehouseEditView /> },
        { path: 'warehouse/update/:id', element: <WarehouseEditView /> },
        { path: 'warehouse/not-found', element: <p>Warehouse not found</p> },
        { path: 'warehouse', element: <Navigate to="/app/warehouses" /> },

        /* Quick filters */
        { path: 'quick-filters', element: <QuickFilterList /> },
        { path: 'quick-filter/create', element: <QuickFilterEditView /> },
        { path: 'quick-filter/update/:id', element: <QuickFilterEditView /> },
        { path: 'quick-filter/not-found', element: <p>Warehouse not found</p> },
        { path: 'quick-filter', element: <Navigate to="/app/quick-filters" /> },

        /* Banks */
        { path: 'banks', element: <BanksList /> },
        { path: 'bank/create', element: <BanksEditView /> },
        { path: 'bank/update/:id', element: <BanksEditView /> },
        { path: 'bank/not-found', element: <p>Banks not found</p> },
        { path: 'bank', element: <Navigate to="/app/banks" /> },

        /* Installment Management */
        { path: 'installment-management', element: <InstallmentManagementList /> },
        { path: 'installment-management/create', element: <InstallmentManagementEditView /> },
        { path: 'installment-management/update/:id', element: <InstallmentManagementEditView /> },
        { path: 'installment-management/not-found', element: <p>Installment not found</p> },
        { path: 'installment-management', element: <Navigate to="/app/installment-management" /> },

        /* Credit Types */
        { path: 'credit-types', element: <CreditTypesList /> },
        { path: 'credit-type/create', element: <CreditTypeEditView /> },
        { path: 'credit-type/update/:id', element: <CreditTypeEditView /> },
        { path: 'credit-type/not-found', element: <p>Credit type not found</p> },
        { path: 'credit-type', element: <Navigate to="/app/credit-types" /> },

        /* Credit offers */
        { path: 'credit-offers', element: <CreditOffersList /> },
        { path: 'credit-offer/create', element: <CreditOffersEditView /> },
        { path: 'credit-offer/update/:id', element: <CreditOffersEditView /> },
        { path: 'credit-offer/not-found', element: <p>Credit offer not found</p> },
        { path: 'credit-offer', element: <Navigate to="/app/credit-offers" /> },

        /* Additional services */
        { path: 'additional-services', element: <AdditionalServicesList /> },
        { path: 'additional-service/create', element: <AdditionalServicesEditView /> },
        { path: 'additional-service/update/:id', element: <AdditionalServicesEditView /> },
        { path: 'additional-service/not-found', element: <p>Additional service offer not found</p> },
        { path: 'additional-service', element: <Navigate to="/app/addtional-services" /> },

        /* Promocode series */
        { path: 'promocode-series', element: <PromocodeSeriesList /> },
        { path: 'promocode-series/update/:id', element: <PromocodeSeriesEditView /> },
        { path: 'promocode-series/not-found', element: <p>Promocode series not found</p> },
        { path: 'promocode-serie', element: <Navigate to="/app/promocode-series" /> },

        /* Promocode */
        { path: 'promocode', element: <PromocodeOfNonPurchasingActivityList /> },
        { path: 'promocode/update/:id', element: <PromocodeOfNonPurchasingActivityEditView /> },
        { path: 'promocode/not-found', element: <p>Promocode of non purchasing activity not found</p> },
        { path: 'promocod', element: <Navigate to="/app/promocode" /> },

        /* Promocode register */
        { path: 'promocode-register', element: <PromocodeRegisterList /> },
        { path: 'promocode-register/update/:id', element: <PromocodeRegisterEditView /> },
        { path: 'promocode-register/not-found', element: <p>Promocode register not found</p> },
        { path: 'promocode-registers', element: <Navigate to="/app/promocode-register" /> },

        /* Promocode service centers */
        { path: 'promocode-service-centers', element: <PromocodeServiceCenterList />},
        { path: 'promocode-service-centers/update/:id', element: <PromocodeServiceCentersEditView />},
        { path: 'promocode-service-centers/not-found', element: <p>Promocode service centers not found</p>},
        { path: 'promocode-service-center', element: <Navigate to="/app/promocode-service-centers" />},

        /* Unaccounted products */
        { path: 'unaccounted-products', element: <UnaccountedProductsList /> },
        { path: 'unaccounted-product/create', element: <UnaccountedProductsEditView /> },
        { path: 'unaccounted-product/update/:id', element: <UnaccountedProductsEditView /> },
        { path: 'unaccounted-product/not-found', element: <p>Unaccounted product not found</p> },
        { path: 'unaccounted-product', element: <Navigate to="/app/unaccounted-products" /> },

        /* Special offers */
        { path: 'spec-offers', element: <SpecOfferList /> },
        { path: 'spec-offer/create', element: <SpecOfferEditView /> },
        { path: 'spec-offer/update/:id', element: <SpecOfferEditView /> },
        { path: 'spec-offer/not-found', element: <p>Special offer not found</p> },
        { path: 'spec-offer', element: <Navigate to="/app/spec-offers" /> },

        /* Special offers banner */
        { path: 'spec-offer-banners', element: <SpecOfferBannerList /> },
        // { path: 'spec-offer-banner/create', element: <SpecOfferBannerEditView /> },
        { path: 'spec-offer-banner/update/:id', element: <SpecOfferBannerEditView /> },
        { path: 'spec-offer-banner/not-found', element: <p>Special offer banner not found</p> },
        { path: 'spec-offer-banner', element: <Navigate to="/app/spec-offer-banners" /> },

        /* Special offers banner tag */
        { path: 'spec-offer-banners/tag', element: <SpecOfferBannerTagList /> },
        { path: 'spec-offer-banners/tag/create', element: <SpecOfferBannerTagEditView /> },
        { path: 'spec-offer-banners/tag/update/:id', element: <SpecOfferBannerTagEditView /> },
        { path: 'spec-offer-banners/tag/not-found', element: <p>Special offer banner tag not found</p> },

        /* Accessory relation */
        { path: 'accessory-relations', element: <AccessoryRelationList /> },
        { path: 'accessory-relation/create', element: <AccessoryRelationEditView /> },
        { path: 'accessory-relation/update/:id', element: <AccessoryRelationEditView /> },
        { path: 'accessory-relation/not-found', element: <p>Accessory relation not found</p> },
        { path: 'accessory-relation', element: <Navigate to="/app/accessory-relation" /> },

        /* Banner management */
        { path: 'banner-management/home', element: <BannerManagementHomeList /> },
        { path: 'banner-management/home/update/:id', element: <BannerManagementHomeEditView /> },
        { path: 'banner-management/home/not-found', element: <p>Banner home not found</p> },

        { path: 'banner-management/menu', element: <BannerManagementMenuList /> },
        { path: 'banner-management/menu/create', element: <BannerManagementMenuEditView /> },
        { path: 'banner-management/menu/update/:id', element: <BannerManagementMenuEditView /> },
        { path: 'banner-management/menu/not-found', element: <p>Banner menu not found</p> },

        { path: 'banner-management/catalog', element: <BannerManagementCatalogList /> },
        { path: 'banner-management/catalog/update/:id', element: <BannerManagementCatalogEditView /> },
        { path: 'banner-management/catalog/not-found', element: <p>Banner catalog not found</p> },

        { path: 'banner-management/head', element: <BannerManagementHeadList /> },
        { path: 'banner-management/head/update/:id', element: <BannerManagementHeadEditView /> },
        { path: 'banner-management/head/not-found', element: <p>Banner head not found</p> },
        { path: 'banner-management', element: <Navigate to="/app/banner-management/home" /> },

        { path: 'banner-management/catalog-horizontal', element: <BannerManagementCatalogHorizontalList /> },
        { path: 'banner-management/catalog-horizontal/update/:id', element: <BannerManagementCatalogHorizontalEditView /> },
        { path: 'banner-management/catalog-horizontal/not-found', element: <p>Banner catalog horizontal not found</p> },

        /* Main page - Benefits */
        { path: 'main/benefits', element: <BenefitList /> },
        { path: 'main/benefit/create', element: <BenefitEditView /> },
        { path: 'main/benefit/update/:id', element: <BenefitEditView /> },
        { path: 'main/benefit/not-found', element: <p>Benefit not found</p> },
        { path: 'main/benefit', element: <Navigate to="/app/main/benefits" /> },

        /* Main page - Popular goods */
        { path: 'main/popular-goods', element: <PopularGoodList /> },
        { path: 'main/popular-good/create', element: <PopularGoodEditView /> },
        { path: 'main/popular-good/update/:id', element: <PopularGoodEditView /> },
        { path: 'main/popular-good/not-found', element: <p>Popular good not found</p> },
        { path: 'main/popular-good', element: <Navigate to="/app/main/popular-goods" /> },

        /* Main page - Unique services */
        { path: 'main/unique-services', element: <UniqueServiceList /> },
        { path: 'main/unique-service/create', element: <UniqueServiceEditView /> },
        { path: 'main/unique-service/update/:id', element: <UniqueServiceEditView /> },
        { path: 'main/unique-service/not-found', element: <p>Unique service not found</p> },
        { path: 'main/unique-service', element: <Navigate to="/app/main/unique-services" /> },

        /* Main page - Services */
        { path: 'main/services', element: <ServiceList /> },
        { path: 'main/service/create', element: <ServiceEditView /> },
        { path: 'main/service/update/:id', element: <ServiceEditView /> },
        { path: 'main/service/not-found', element: <p>Service not found</p> },
        { path: 'main/service', element: <Navigate to="/app/main/services" /> },

        /* Main page - Newest */
        { path: 'main/newest', element: <NewestList /> },
        { path: 'main/newest/create', element: <NewestEditView /> },
        { path: 'main/newest/update/:id', element: <NewestEditView /> },
        { path: 'main/newest/not-found', element: <p>Newest not found</p> },

        /* Main page - Popular categories */
        { path: 'main/popular-categories', element: <PopularCategoryList /> },
        { path: 'main/popular-category/create', element: <PopularCategoryEditView /> },
        { path: 'main/popular-category/update/:id', element: <PopularCategoryEditView /> },
        { path: 'main/popular-category/not-found', element: <p>Popular category not found</p> },
        { path: 'main/popular-category', element: <Navigate to="/app/main/popular-categories" /> },

        { path: 'main', element: <Navigate to="/app/main/benefits" /> },

        /* Menu - Top and bottom */
        { path: 'menu/top-and-bottom', element: <TopAndBottomMenuList /> },
        { path: 'menu/top-and-bottom/create', element: <TopAndBottomEditView /> },
        { path: 'menu/top-and-bottom/update/:id', element: <TopAndBottomEditView /> },
        { path: 'menu/top-and-bottom/not-found', element: <p>Menu not found</p> },

        /* Menu - Categories */
        { path: 'menu/categories', element: <CategoriesMenuList /> },
        { path: 'menu/categories/create', element: <CategoriesMenuEditView /> },
        { path: 'menu/categories/update/:id', element: <CategoriesMenuEditView /> },
        { path: 'menu/categories/not-found', element: <p>Categories menu not found</p> },

        { path: 'menu', element: <Navigate to="/app/menu/top-and-bottom" /> },

        /* In-cart */
        { path: 'in-carts', element: <InCartList /> },
        { path: 'in-cart/update/:id', element: <InCartEditView /> },
        { path: 'in-cart/not-found', element: <p>In cart not found</p> },
        { path: 'in-cart', element: <Navigate to="/app/in-carts" /> },

        // /* Product-feedback */
        { path: 'product-feedback', element: <ProductFeedbackList /> },
        { path: 'product-feedback/create', element: <ProductFeedbackEditView /> },
        { path: 'product-feedback/update/:id', element: <ProductFeedbackEditView /> },
        { path: 'product-feedback/not-found', element: <p>Product feedback not found</p> },

        // /* Product-consultation */
        { path: 'product-consultation', element: <ProductConsultationList /> },
        { path: 'product-consultation/create', element: <ProductConsultationEditView /> },
        { path: 'product-consultation/update/:id', element: <ProductConsultationEditView /> },
        { path: 'product-consultation/not-found', element: <p>Product consultation not found</p> },

        // /* Product-b2b */
        { path: 'product-b2b', element: <ProductB2BList /> },
        { path: 'product-b2b/create', element: <ProductB2BEditView /> },
        { path: 'product-b2b/update/:id', element: <ProductB2BEditView /> },
        { path: 'product-b2b/not-found', element: <p>Product b2b not found</p> },

        // /* Permissions */
        { path: 'permissions/admin', element: <PermissionAdminList /> },
        { path: 'permissions/client', element: <PermissionClientList /> },
        { path: 'permissions', element: <Navigate to="/app/permissions/admin" /> },

        /* Roles */
        { path: 'roles', element: <RoleList /> },
        { path: 'role/create', element: <RoleEditView /> },
        { path: 'role/update/:id', element: <RoleEditView /> },
        { path: 'role/not-found', element: <p>Role not found</p> },
        { path: 'role', element: <Navigate to="/app/roles" /> },

        /* Feeds */
        { path: 'feeds', element: <FeedsList /> },
        { path: 'feed/create', element: <FeedsEditView /> },
        { path: 'feed/update/:id', element: <FeedsEditView /> },
        { path: 'feed/not-found', element: <p>Feed not found</p> },
        { path: 'feed', element: <Navigate to="/app/feeds" /> },

        /* Orders */
        { path: 'orders', element: <OrderList /> },
        { path: 'order/create', element: <OrderEditView /> },
        { path: 'order/update/:id', element: <OrderEditView /> },
        { path: 'order/update/:id/document', element: <OrderDocumentList /> },
        { path: 'order/update/:id/document/:id', element: <OrderDocumentEditView /> },
        { path: 'order/update/:id/document/not-found', element: <p>Order document not found</p> },
        { path: 'order/not-found', element: <p>Order not found</p> },
        { path: 'order', element: <Navigate to="/app/orders" /> },

        /* Templates */
        { path: 'templates', element: <TemplateList /> },
        { path: 'template/create', element: <TemplateEditView /> },
        { path: 'template/update/:id', element: <TemplateEditView /> },
        { path: 'template/not-found', element: <p>Templates not found</p> },
        { path: 'template', element: <Navigate to="/app/templates" /> },

        /* Users */
        { path: 'users/client', element: <UsersClientList /> },
        { path: 'user/client/create', element: <UserClientEditView /> }, // client create
        { path: 'user/client/update/:id', element: <UserClientEditView /> }, // client change
        { path: 'user/not-found', element: <p>User not found</p> },
        { path: 'users', element: <Navigate to="/app/users/client" /> },

        /* Admins */
        { path: 'admins', element: <AdminsList /> },
        { path: 'admin/create', element: <AdminEditView /> },  // admin create
        { path: 'admin/update/:id', element: <AdminEditView /> }, // admin change
        { path: 'admin/not-found', element: <p>Admin not found</p> },
        { path: 'admin', element: <Navigate to="/app/admins" /> },

        /* Payment */
        { path: 'payments', element: <PaymentList /> },
        { path: 'payment/update/:id', element: <PaymentEditView /> },
        { path: 'payment/not-found', element: <p>Payment not found</p> },
        { path: 'payment', element: <Navigate to="/app/payments" /> },

        /* Delivery */
        { path: 'deliveries', element: <DeliveryList /> },
        { path: 'delivery/update/:id', element: <DeliveryEditView /> },
        { path: 'delivery/not-found', element: <p>Delivery not found</p> },
        { path: 'delivery', element: <Navigate to="/app/deliveries" /> },

        /* Contacts */
        { path: 'contacts', element: <ContactList /> },
        { path: 'contact/create', element: <ContactEditView /> },
        { path: 'contact/update/:id', element: <ContactEditView /> },
        { path: 'contact/not-found', element: <p>Contact not found</p> },
        { path: 'contact', element: <Navigate to="/app/contacts" /> },

        /* Domains */
        { path: 'domains', element: <DomainList /> },
        { path: 'domain/create', element: <DomainsEditView /> },
        { path: 'domain/update/:id', element: <DomainsEditView /> },
        { path: 'domain/not-found', element: <p>Domain not found</p> },
        { path: 'domain', element: <Navigate to="/app/domains" /> },

        /* Email-subscription */
        { path: 'email-subscription', element: <EmailSubscription /> },
        { path: 'email-subscription/not-found', element: <p>Email subscription not found</p> },
        { path: 'email-subscription', element: <Navigate to="/app/email-subscription" /> },

        /* Redirects */
        { path: 'redirects', element: <RedirectsList /> },
        { path: 'redirect/create', element: <RedirectEditView /> },
        { path: 'redirect/update/:id', element: <RedirectEditView /> },
        { path: 'redirect/not-found', element: <p>Redirect not found</p> },
        { path: 'redirect', element: <Navigate to="/app/page-settings" /> },

        /* PAGE SETTINGS */
        { path: 'page-settings', element: <PageSettingsList /> },
        { path: 'page-setting/create', element: <PageSettingEditView /> },
        { path: 'page-setting/update/:id', element: <PageSettingEditView /> },
        { path: 'page-setting/not-found', element: <p>Page setting not found</p> },
        { path: 'page-setting', element: <Navigate to="/app/page-settings" /> },

        /* STATUSES */
        { path: 'statuses', element: <StatusesList /> },
        { path: 'status/create', element: <StatusEditView /> },
        { path: 'status/update/:id', element: <StatusEditView /> },
        { path: 'status/not-found', element: <p>Status not found</p> },
        { path: 'statuses', element: <Navigate to="/app/statuses" /> },

        /* STATUS CHANGE */
        { path: 'status-change', element: <StatusChangeList /> },
        { path: 'status-change/create', element: <StatusChangeEditView /> },
        { path: 'status-change/update/:id', element: <StatusChangeEditView /> },
        { path: 'status-change/not-found', element: <p>Status change not found</p> },
        { path: 'status-change', element: <Navigate to="/app/status-change" /> },

        /* purchase */
        { path: 'purchases', element: <PurchaseList /> },
        { path: 'purchase/not-found', element: <p>Purchase not found</p> },
        { path: 'purchase', element: <Navigate to="/app/purchases" /> },

        /* devices */
        { path: 'devices', element: <DevicesList /> },
        { path: 'device/create', element: <DevicesViewEdit /> },
        { path: 'device/update/:id', element: <DevicesViewEdit /> },
        { path: 'device/not-found', element: <p>Device not found</p> },
        { path: 'device', element: <Navigate to="/app/devices" /> },

        /* specialists */
        { path: 'specialists', element: <SpecialistsList /> },
        { path: 'specialist/create', element: <SpecialistsEditView /> },
        { path: 'specialist/update/:id', element: <SpecialistsEditView /> },
        { path: 'specialist/not-found', element: <p>Specialist not found</p> },
        { path: 'specialist', element: <Navigate to="/app/specialists" /> },

        /* trade-in */
        { path: 'trade-in', element: <TradeInList /> },
        { path: 'trade-in/not-found', element: <p>Trade in not found</p> },
        { path: 'trade-ins', element: <Navigate to="/app/trade-in" /> },

        /* subscribers */
        { path: 'subscribers', element: <SubscribersList /> },
        { path: 'subscriber/create', element: <SubscriberEditView /> },
        { path: 'subscriber/update/:id', element: <SubscriberEditView /> },
        { path: 'subscriber/not-found', element: <p>Subscriber not found</p> },
        { path: 'subscriber', element: <Navigate to="/app/subscribers" /> },

        /* subscribers */
        { path: 'galaxy-cafe', element: <GalaxyCafeList /> },
        { path: 'GalaxyCafe', element: <Navigate to="/app/galaxy-cafe" /> },

        /* articles */
        { path: 'articles', element: <ArticlesList /> },
        { path: 'article/create', element: <ArticleEditView /> },
        { path: 'article/update/:id', element: <ArticleEditView /> },
        { path: 'article/not-found', element: <p>Article not found</p> },
        { path: 'article', element: <Navigate to="/app/articles" /> },

        /* reviews */
        { path: 'reviews', element: <ReviewsList /> },
        { path: 'review/create', element: <ReviewEditView /> },
        { path: 'review/update/:id', element: <ReviewEditView /> },
        { path: 'review/not-found', element: <p>Review not found</p> },
        { path: 'review', element: <Navigate to="/app/reviews" /> },

        /* articles */
        { path: 'articles', element: <ArticlesList /> },
        { path: 'article/create', element: <ArticleEditView /> },
        { path: 'article/update/:id', element: <ArticleEditView /> },
        { path: 'article/not-found', element: <p>Article not found</p> },
        { path: 'article', element: <Navigate to="/app/articles" /> },

        /* brands */
        { path: 'brands', element: <BrandsList /> },
        { path: 'brand/create', element: <BrandEditView /> },
        { path: 'brand/update/:id', element: <BrandEditView /> },
        { path: 'brand/not-found', element: <p>Brand not found</p> },
        { path: 'brand', element: <Navigate to="/app/brands" /> },

        /* reports */
        { path: 'reports/orders', element: <ReportsOrders /> },
        { path: 'reports/order', element: <Navigate to="/app/reports/orders" /> },

        /* media */
        { path: 'medias', element: <MediaList /> },
        { path: 'media/create', element: <MediaEditView /> },
        { path: 'media/update/:id', element: <MediaEditView /> },
        { path: 'media/not-found', element: <p>Media not found</p> },
        { path: 'media', element: <Navigate to="/app/medias" /> },

        /* try and buy */
        { path: 'try-and-buy', element: <TryAndBuyList /> },
        { path: 'try-and-buy/not-found', element: <p>Try and buy not found</p> },
        { path: 'try-and-buys', element: <Navigate to="/app/try-and-buy" /> },

        /* SC claim */
        { path: 'service-centers/registrations', element: <ClaimList />},
        { path: 'service-centers/registrations/not-found', element: <p>Page not found</p>},
        { path: 'sc-claim-list', element: <Navigate to="/app/service-centers/registrations" />},

        /* Subscription Events */
        { path: 'subscriber-types', element: <SubscriberEvents/> },
        { path: 'subscriber-types/not-found', element: <p>Page not found</p> },
        { path: 'sc-claim-list', element: <Navigate to="/app/subscriber-types"/> },

        /* cooperation b2b */
        { path: 'cooperation-b2b', element: <CooperationB2BList /> },
        { path: 'cooperation-b2b/not-found', element: <p>Cooperation B2B not found</p> },
        { path: 'cooperations-b2b', element: <Navigate to="/app/cooperation-b2b" /> },

        /* easy-pro */
        { path: 'easy-pro', element: <EasyProList /> },
        { path: 'easy-pro/not-found', element: <p>Easy Pro not found</p> },
        { path: 'easy-pros', element: <Navigate to="/app/easy-pro" /> },

        /* hr-domain-mask */
        { path: 'hr-domain-mask', element: <HrDomainMaskList /> },
        { path: 'hr-domain-mask/update/:id', element: <HrDomainMaskViewEdit /> },
        { path: 'hr-domain-mask/not-found', element: <p>HR domain mask not found</p> },
        { path: 'hr-domain-mask', element: <Navigate to="/app/hr-domain-mask" /> },

        /* sort/products */
        { path: 'sort/products', element: <SortProductsList /> },
        { path: 'sort/product/not-found', element: <p>Sort product not found</p> },
        { path: 'sort/product', element: <Navigate to="/app/sort/products" /> },

        /* sort/special-offers */
        { path: 'sort/special-offers', element: <SortSpecOffersList /> },
        { path: 'sort/special-offers/not-found', element: <p>Sort special offers not found</p> },
        { path: 'sort/special-offer', element: <Navigate to="/app/sort/special-offers" /> },

        /* sort/banner/catalog */
        { path: 'sort/banner/catalog', element: <SortBannerCatalogList /> },
        { path: 'sort/banner/catalog/not-found', element: <p>Sort banner catalog not found</p> },
        { path: 'sort/banner/catalogs', element: <Navigate to="/app/sort/banner/catalog" /> },

        /* sort/banner/catalog-horizontal */
        { path: 'sort/banner/catalog-horizontal', element: <SortBannerCatalogHorizontalList /> },
        { path: 'sort/banner/catalog-horizontal/not-found', element: <p>Sort banner catalog horizontal not found</p> },
        { path: 'sort/banner/catalog-horizontals', element: <Navigate to="/app/sort/banner/catalog-horizontal" /> },

        /* sort/header */
        { path: 'sort/banner/header', element: <SortBannerHeaderList /> },
        { path: 'sort/banner/header/not-found', element: <p>Sort banner header not found</p> },
        { path: 'sort/banner/headers', element: <Navigate to="/app/sort/banner/header" /> },

        /* sort/main-page */
        { path: 'sort/banner/main-page', element: <SortBannerMainPageList /> },
        { path: 'sort/banner/main-page/not-found', element: <p>Sort banner main page not found</p> },
        { path: 'sort/banner/main-pages', element: <Navigate to="/app/sort/banner/main-page" /> },

        /* sort/menu */
        { path: 'sort/banner/menu', element: <SortBannerMenuList /> },
        { path: 'sort/banner/menu/not-found', element: <p>Sort banner menu not found</p> },
        { path: 'sort/banner/menus', element: <Navigate to="/app/sort/banner/menu" /> },

        /* sort/categories */
        { path: 'sort/categories', element: <SortCategoriesList /> },
        { path: 'sort/categories/not-found', element: <p>Sort category not found</p> },
        { path: 'sort/categories', element: <Navigate to="/app/sort/category" /> },

        /* sort/banks-pay-part */
        { path: 'sort/banks-pay-part', element: <SortBankPPList /> },
        { path: 'sort/banks-pay-part/not-found', element: <p>Sort category not found</p> },
        { path: 'sort/banks-pay-part', element: <Navigate to="/app/sort/bank-pay-part" /> },

        /* documents */
        { path: 'documents', element: <DocumentsList /> },
        { path: 'documents/not-found', element: <p>Document not found</p> },
        { path: 'document', element: <Navigate to="/app/documents" /> },

        /* Collections */
        { path: 'selections', element: <SelectionsList /> },
        { path: 'selection/create', element: <SelectionEditView /> },
        { path: 'selection/update/:id', element: <SelectionEditView /> },
        { path: 'selection/not-found', element: <p>Collection not found</p> },
        { path: 'selection', element: <Navigate to="/app/selections" /> },

        /* discount-pks */
        { path: 'discount-pks', element: <DiscountPks /> },
        { path: 'discount-pks/not-found', element: <p>Discount PKS not found</p> },
        { path: 'discounts-pks', element: <Navigate to="/app/discount-pks" /> },

        /* Sections */
        { path: 'sections', element: <SectionsList /> },
        { path: 'section/create', element: <SectionEditView /> },
        { path: 'section/update/:id', element: <SectionEditView /> },
        { path: 'section/not-found', element: <p>Collection not found</p> },
        { path: 'section', element: <Navigate to="/app/sections" /> },

        /* Dashboard */
        { path: '/', element: checkPermission('admin.order.index') ? <Navigate to="/app/orders" /> : <Dashboard /> },
        { path: '*', element: <p>Page not found</p> },
      ],
    },
    {
      path: '/',
      element: isAuthenticated ? <Navigate to="/app" /> : <MainLayout isAuthenticated={isAuthenticated} /> ,
      children: [
        { path: '/', element: isAuthenticated ? <Dashboard /> : <Navigate to="/login" /> },
        { path: 'login', element: <LoginView /> },
        // { path: 'signup', element: <RegisterView /> },
        { path: 'password-reset', element: <PasswordReset /> },
        { path: 'password-reset/:slug', element: <PasswordReset /> },
        { path: '*', element: <p>Page not found</p> },
      ],
    }
  ];
}

export default routes;
