import { useLocation, useNavigate } from "react-router-dom";
import React, {useState} from "react";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import { getData, getFiltersData } from "./api";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import {DEVELOPMENT_ENVIRONMENT, TRY_AND_BUY_CREATE} from "../../const/api";
import { Helmet } from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import getTZtoDate from "../../lib/getTZtoDate";
import TopPanelNew from "../../components/common/TopPanelNew";
import {ModalEditTryAndBuy} from "./Modal";
import StatusCircle from "../../components/ui/StatusCircle";

const TryAndBuyList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
    filtersData,
  } = useList({
    api: getData,
    apiFilters: getFiltersData,
    location,
    navigate,
    isMounted,
  });

  const findStatusColor = (value) => {
      switch (value) {
          case "done": {
              return <StatusCircle green text={filtersData?.status.find(item => item.value?.toString() === value?.toString()).title} />
          }

          case "in_use": {
              return <StatusCircle blue text={filtersData?.status.find(item => item.value?.toString() === value?.toString()).title} />
          }

          case "waiting": {
              return <StatusCircle yellow text={filtersData?.status.find(item => item.value?.toString() === value?.toString()).title} />
          }

          case "cancelled": {
              return <StatusCircle red text={filtersData?.status.find(item => item.value?.toString() === value?.toString()).title} />
          }

          default: {
              return <StatusCircle grey text={filtersData?.status.find(item => item.value?.toString() === value?.toString()).title} />
          }
      }
  };

  const [activeModal, setActiveModal] = useState(false);
  const [activeId, setActiveId] = useState(false);

  const devicesToUse = filtersData?.devices && filtersData?.devices?.filter(el => el?.is_available).map(el => el?.device_numbers?.length)

  return (
    <>
        <TopPanelNew
            title="Try and Buy"
            breadcrumbs={{
                items: [
                    {
                        title: 'Головна',
                        url: '/app',
                        permissionName: 'admin.test-drive.index',
                    },
                    {
                        title: 'Try and Buy',
                        url: '/app/try-and-buy',
                        permissionName: 'admin.test-drive.index',
                    },
                ],
            }}
            btnClearParams={{
                title: 'Скинути фільтри',
                href: '/app/try-and-buy',
                permissionName: 'admin.test-drive.index',
            }}
            btnModal={{
                title: 'Створити заявку на Try and Buy',
                api: TRY_AND_BUY_CREATE,
                href: '/app/try-and-buy',
                permissionName: 'admin.test-drive.store',
                redirect: false,
                fields: [
                    { type: 'text', name: 'name', label: "Прізвище Ім'я", placeholder: "Прізвище Ім'я" },
                    { type: 'phone', name: 'phone', label: 'Телефон', placeholder: 'Телефон' },
                    { type: 'select_try_and_buy', name: 'settlement_id', label: 'Місто', placeholder: 'Місто', options: filtersData?.shops && filtersData?.shops, optProps: ['id', 'title'] },
                    { type: 'select_try_and_buy', name: 'shop_id', label: 'Магазин', placeholder: 'Магазин', options: filtersData?.shops && filtersData?.shops, optProps: ['id', 'title'] },
                    { type: 'date', name: 'preferred_date', label: 'Бажана дата', min: 2, max: 4, placeholder: 'Бажана дата' },
                    { type: 'select', name: 'device_number', label: 'Пристрій', placeholder: 'Пристрій', options: filtersData?.devices && filtersData?.devices?.flatMap(e =>
                        e?.device_numbers?.length ? { device_id: +e.id, title: `${e?.title}`, id: +e.device_numbers[0] } : [] )
                  , optProps: ['device_id', 'title',  'id'] },
                    { type: 'select', name: 'status', label: 'Статус', placeholder: 'Статус', options: filtersData?.status && filtersData?.status, optProps: ['value', 'title'] },
                ],
                modalStyles:{maxHeight: "100vh"},
                numOfDevices: devicesToUse?.length ? devicesToUse?.reduce((a,b) => a + b)
                    : 0
                // defaultSendValue: {
                //     device_id: filtersData?.devices && +filtersData?.devices[0]?.id,
                // },
            }}
        />
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Try and buy list`} />
            {!load && (
                <TopPagination
                    tabs={(
                        <div>Вільних пристроїв - <b>{devicesToUse?.length ? devicesToUse?.reduce((a,b) => a + b)
                            : 0}</b></div>
                    )}
                    pagination={(
                        <>
                            {+rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Ім'я</th>
                        <th>Телефон</th>
                        <th>Місто</th>
                        <th>Магазин</th>
                        <th>Створений</th>
                        <th>Бажана дата</th>
                        <th>Пристрій</th>
                        <th>Дата видачі девайсу</th>
                        <th>Дата повернення девайсу</th>
                        <th>Статус</th>
                        <th> </th>
                    </tr>

                    <TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            { type: 'text', name: 'name' },
                            { type: 'text', name: 'phone'},
                            { type: 'select_try_and_buy', name: 'settlement_id', options: filtersData?.shops && filtersData?.shops, optProps: ['id', 'title'] },
                            { type: 'select_try_and_buy', name: 'shop_id', options: filtersData?.shops && filtersData?.shops, optProps: ['id', 'title'] },
                            { type: 'date_range', startName: 'created_date_from', endName: 'created_date_to', height: '50px', display: 'block', maxWidth: "138px", minWidth: "138px" },
                            { type: 'date_range', startName: 'preferred_date_from', endName: 'preferred_date_to', height: '50px', display: 'block', maxWidth: "138px", minWidth: "138px" },
                            { type: 'checkbox', name: 'is_taken', label: 'Занят' },
                            { type: 'date', name: 'start_using_at'},
                            { type: 'date', name: 'finish_using_at' },
                            { type: 'select', name: 'status', options: filtersData?.status && filtersData?.status, optProps: ['value', 'title'] },
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />

                </thead>
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={10} />
                    )
                    : (rest && (
                        rest?.data?.length !== 0
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`try-and-buy-item-${e.id}`}
                                    permissionEdit='admin.test-drive.edit'
                                    deleteButton={false}
                                    setActiveModal={setActiveModal}
                                    setActiveId={setActiveId}
                                    tagA={false}
                                    editLink
                                >
                                    <td>
                                        <span>{e.id}</span>
                                    </td>
                                    <td>
                                        <span>{e.name}</span>
                                    </td>
                                    <td>
                                        <span>{e.phone}</span>
                                    </td>
                                    <td>
                                        <span>{e?.settlement ? `${e?.settlement.title}` : '---'}</span>
                                    </td>
                                    <td>
                                        <span>{e?.shop?.title}</span>
                                    </td>
                                    <td>
                                        <span>{e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}</span>
                                    </td>
                                    <td>
                                        <span>{e?.preferred_date ? `${e?.preferred_date}` : '---'}</span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.device?.title && e?.device_number && e?.status === 'in_use' ? (
                                                <div style={{display: "flex"}}>
                                                    {e?.device?.title}
                                                    <div
                                                        style={{
                                                            marginLeft: "3px",
                                                            width: "15px",
                                                            height: "15px",
                                                            borderRadius: "25px",
                                                            background: "rgb(73 148 237 )",
                                                            color: "white",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        {e?.device_number}
                                                    </div>
                                                </div>
                                            ) : e?.device?.title && (e?.status === 'waiting' || e?.status === 'cancelled') ? (
                                                <div style={{display: "flex"}}>
                                                    {e?.device?.title}
                                                    <div
                                                        style={{
                                                            marginLeft: "3px",
                                                            width: "15px",
                                                            height: "15px",
                                                            borderRadius: "25px",
                                                            background: "rgb(128 128 128)",
                                                            color: "white",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        ?
                                                    </div>
                                                </div>
                                            ) : e?.device?.title && e?.device_number ? (
                                                <div style={{display: "flex"}}>
                                                    {e?.device?.title}
                                                    <div
                                                        style={{
                                                            marginLeft: "3px",
                                                            width: "15px",
                                                            height: "15px",
                                                            borderRadius: "25px",
                                                            background: "rgb(128 128 128)",
                                                            color: "white",
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        {e?.device_number}
                                                    </div>
                                                </div>

                                            ) : (
                                                '---'
                                            )}

                                        </span>
                                    </td>
                                    <td>
                                        <span>{e?.start_using_at ? getTZtoDate(e.start_using_at, true) : '---'}</span>
                                    </td>
                                    <td>
                                        <span>{e?.finish_using_at ? getTZtoDate(e.finish_using_at, true) : '---'}</span>
                                    </td>
                                    <td>
                                        <span>
                                            {findStatusColor(e?.status)}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                  <td colSpan={10} style={{paddingTop: '22px'}}>Немає результатів</td>
                                </TableListViewRow>
                          )
                    ))}
                </tbody>
            </TableListViewTable>
            {activeModal && (
                <ModalEditTryAndBuy numOfDevices={devicesToUse?.length ? devicesToUse?.reduce((a,b) => a + b)
                    : 0} rest={rest} setActive={setActiveModal} active={activeModal} filtersData={filtersData} activeId={activeId} title="Заявка на Try and Buy" />
            )}
        </div>
    </>
  );
};

export default TryAndBuyList;
