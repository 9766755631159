import React, {useEffect, useState} from "react";
import s from "./ModalCreate.module.scss";
import {useForm} from "react-hook-form";
import Form from "../../../components/ui/FormEditView/Form";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import Group from "../../../components/ui/FormEditView/Group";
import Button from "../../../components/ui/Button";
import Loader from "../../../components/ui/Loader";
import CheckboxSwitch from "../../../components/ui/CheckboxSwitch";
import {getBankSelectionEdit, setBankSelections, updateBankSelections} from "../../../lib/api/banks";
import errorMessage from "../../../lib/errorMessage";
import InputDateNew from "../../../components/ui/InputDateNew";
import RSelect from "../../../components/ui/RSelect";

const ModalCreate = ({onClose, activeId, rest, title, filtersData}) => {
    const formH = useForm();
    const [load, setLoad] = useState(false);
    const [confirmCreate, setConfirmCreate] = useState(false);

    const [sortArray, setSortArray] = useState([1]);
    const [draggedItem, setDraggedItem] = useState(null);
    const [bankItems, setBankItems] = useState(filtersData?.banks?.data || null);
    const [selected, setSelected] = useState(null);
    const [selectedValue, setSelectedValue] = useState(0);
    const [position, setPosition] = useState(1);
    const [selectedType3, setSelectedType3] = useState();

    const {
        handleSubmit,
        register
    } = formH;

    const [date, setDate] = useState({
        date_from: '',
        date_to: '',
    });

    useEffect(() => {
        if (filtersData?.fields?.date_from){
            const timeFrom = filtersData?.fields?.date_from?.split(' ');
            setDate(prev => ({
                ...prev,
                date_from: timeFrom[0],
            }));
        }
    }, [filtersData])

    const submitHandler = async(data) => {
        setLoad(true);
        const bank_ids = bankItems?.map(e => e.id)
        console.log(data)

        delete data['starts_at-dop']
        delete data['finish_at-dop']

        if (!activeId){
            await setBankSelections({
                ...data,
                bank_ids,
                starts_at: data['starts_at'] + ' 00:00:00',
                finish_at: data['finish_at'] + ' 23:59:59',
                selection_id: +data?.selection_id,
                name: filtersData?.selections?.data?.filter(e => e.id === +data?.selection_id)[0]?.title,
                position: +data.position
            }).then(res => {
                if (!!res.data){
                    window.location.reload();
                }
                return res;
            }).catch(err => errorMessage(err, '')).finally(() => setLoad(false));
        } else {
            await updateBankSelections(activeId, {
                ...data,
                bank_ids,
                starts_at: data['starts_at'] + ' 00:00:00',
                finish_at: data['finish_at'] + ' 23:59:59',
                name: filtersData?.selections?.data?.filter(e => e.id === +data?.selection_id)[0]?.title,
                position: +data.position
            }).then(res => {
                if (!!res.data){
                    window.location.reload();
                }
                return res;
            }).catch(err => errorMessage(err, '')).finally(() => setLoad(false));
        }
    };

    useEffect(() => {
      const num = activeId ? 1 : 2
        if (rest?.meta?.total){
            const totalArray = [...Array(rest?.meta?.total + num).keys()].filter(item => !!item);
            if (rest?.meta?.total === 20){
                const defaultArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
                setSortArray(defaultArray);
            } else {
                setSortArray(totalArray);
            }
        }
    }, [rest?.meta?.total]);

    const handleDragStart = (index) => {
        setDraggedItem(index);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (index) => {
        const newBankItems = [...bankItems];
        const [removed] = newBankItems.splice(draggedItem, 1);
        newBankItems.splice(index, 0, removed);
        setBankItems(newBankItems);
        setDraggedItem(null);
    };

    useEffect(() => {
        if(activeId){
            setLoad(true);
            getBankSelectionEdit(activeId)
                .then((res) => {
                    const sortedBanks = res?.data?.bank_ids.map(id => filtersData?.banks?.data.find(item => item.id === id))
                    console.log(res?.data?.selection_id)
                    setSelected(res?.data)
                    setSelectedType3({id: res?.data?.selection_id, title: res?.data?.name, name: res?.data?.name})
                    setPosition(res?.data?.position)
                    setBankItems(sortedBanks);
                })
                .catch(async(err) => await errorMessage(err, ''))
                .finally(() => setLoad(false));
        }
    }, [activeId]);

    return (
        <div className={s.wrap}>
            <div className={s.title}
                 style={{
                     maxWidth: !confirmCreate ? 'max-content' : '100%',
                     textAlign: !confirmCreate ? 'left' : 'center'
                 }}
            >
                {!confirmCreate ? title : 'Помилка перетину!'}
            </div>
            <Form attrs={{onSubmit: handleSubmit(submitHandler), style: {paddingTop: 0}}}>
                <Group attrs={{className: confirmCreate && s.hidden_block, style: {marginBottom: "20px"}}}>
                    <ColFull>
                            <RSelect
                                props={{
                                    value: selectedType3,
                                    onChange: (opt) => {
                                        setSelectedType3(opt)
                                    },
                                    placeholder: 'Обрати Вибірки',
                                    options: filtersData?.selections?.data.map((el) => {
                                        return {...el}
                                    }),
                                    isMulti: false,
                                    getOptionLabel: (opt) => opt.title,
                                    getOptionValue: (opt) => opt.id,
                                }}
                            />

                            {selectedType3 &&
                                <input
                                    type="hidden"
                                    ref={formH.register()}
                                    name={`selection_id`}
                                    value={selectedType3.id}
                                />
                            }
                    </ColFull>
                </Group>

                <div style={{
                    margin: "20px 0px 10px 0", display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "20px"
                }}>
                    {/* starts_at */}
                    <InputDateNew
                        name="starts_at"
                        className="form-input"
                        id="bank-starts-at"
                        defVal={selected && selected.starts_at.split(' ')[0]}
                        placeholder="Термін дії від"
                        formH={formH}
                        withZero={false}
                        style={{marginRight: '0'}}
                    />

                    {/* finish_at */}
                    <InputDateNew
                        name="finish_at"
                        className="form-input"
                        id="bank-finish-at"
                        defVal={selected && selected.finish_at.split(' ')[0]}
                        placeholder="Термін дії до"
                        formH={formH}
                        withZero={false}
                        style={{marginRight: '0'}}
                    />
                </div>

                <div className={s_Select.select__inner} style={{marginBottom: "20px"}}>
                    <div className={s_Select.select__wrapper}>
                        <select
                            name="position"
                            className={s_Select.select}
                            id="bank-sort"
                            ref={register}
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                        >
                            {sortArray.map((itemSort) => (
                                <option key={`modal-sort-item-${itemSort}`} value={itemSort}>
                                    {itemSort}
                                </option>
                            ))}
                        </select>
                        <label className={s_Select.label} htmlFor="bank-sort">Позиція</label>
                    </div>
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: "20px"}}>
                    {bankItems?.map((bank, index) => (
                        <div
                            key={bank?.id}
                            className={s.draggableItem}
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragOver={handleDragOver}
                            onDrop={() => handleDrop(index)}
                            style={{
                                paddingLeft: index !== 0 ? '10px' : '0px',
                                paddingRight: index !== bankItems.length - 1 ? '10px' : '0px'
                            }}
                        >
                            <img
                                src={`https://cdn-samsungshop.dev.org.ua/${bank?.img}`}
                                alt={bank?.title_ua || 'Банк'}
                                className={s.bankImage}
                            />
                        </div>
                    ))}
                </div>

                {confirmCreate && rest?.meta?.total >= 20 && (
                    <div style={{marginBottom: 24, textAlign: 'center'}}>
                        <span className={s.text_confirm}>
                            Неможливо створити налаштування, тому що товари або категорії задіяні в налаштуванні “{chooseCategory?.title_ua}” перетинаються з товарами або категоріями з поточного налаштування.
                        </span>
                        <br/>
                        <strong className={s.text_confirm}>
                            Щоб створити дані налаштування, необхідно видалити або відредагувати “{chooseCategory?.title_ua}”.
                        </strong>
                    </div>
                )}
                {!confirmCreate && !rest?.meta?.total >= 20 ?
                    <div style={{width: "100%", display: "flex", justifyContent: "center"}}><Button onClick={onClose} border>Зрозуміло</Button>
                    </div> :
                    <div className={s.footer_wrap}>
                        <div>
                            <CheckboxSwitch
                                name="is_active"
                                id="marker-is-active"
                                label="Активність"
                                formH={formH}
                                defaultChecked={selected && selected.is_active}
                            />
                        </div>
                        <div className={s.btn_wrap}>
                            <Button
                                type="button"
                                onClick={handleSubmit(submitHandler)}
                                purple
                            >
                                {activeId ? "Редагувати" : "Додати"}
                            </Button>
                            <Button onClick={onClose} border>Скасувати</Button>
                        </div>
                    </div>
                }
                {load && <Loader/>}
            </Form>
        </div>
    );
};

export default ModalCreate;