import React from "react";
import dragHandleImage from './dragImg.png';

const DragHandle = (props) => {
    return (
        <div {...props} style={{
            height: '14px',
            verticalAlign: 'bottom',
            display: 'inline-block',
            marginRight: '0.5rem',
            cursor: props?.isDragging ? "grabbing" : "grab",
        }}>
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3684_10769)">
                    <path
                        d="M3.5 12.25C3.5 13.2125 2.7125 14 1.75 14C0.7875 14 0 13.2125 0 12.25C0 11.2875 0.7875 10.5 1.75 10.5C2.7125 10.5 3.5 11.2875 3.5 12.25ZM1.75 5.25C0.7875 5.25 0 6.0375 0 7C0 7.9625 0.7875 8.75 1.75 8.75C2.7125 8.75 3.5 7.9625 3.5 7C3.5 6.0375 2.7125 5.25 1.75 5.25ZM1.75 0C0.7875 0 0 0.7875 0 1.75C0 2.7125 0.7875 3.5 1.75 3.5C2.7125 3.5 3.5 2.7125 3.5 1.75C3.5 0.7875 2.7125 0 1.75 0ZM7 3.5C7.9625 3.5 8.75 2.7125 8.75 1.75C8.75 0.7875 7.9625 0 7 0C6.0375 0 5.25 0.7875 5.25 1.75C5.25 2.7125 6.0375 3.5 7 3.5ZM7 5.25C6.0375 5.25 5.25 6.0375 5.25 7C5.25 7.9625 6.0375 8.75 7 8.75C7.9625 8.75 8.75 7.9625 8.75 7C8.75 6.0375 7.9625 5.25 7 5.25ZM7 10.5C6.0375 10.5 5.25 11.2875 5.25 12.25C5.25 13.2125 6.0375 14 7 14C7.9625 14 8.75 13.2125 8.75 12.25C8.75 11.2875 7.9625 10.5 7 10.5Z"
                        fill="#5755D1"/>
                </g>
                <defs>
                    <clipPath id="clip0_3684_10769">
                        <rect width="9" height="14" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </div>
    );
};

export default DragHandle;