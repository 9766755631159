import React from "react";
import s from "./CheckboxList.module.scss";
import s_FormSelect from "../FormEditView/FormCheckbox/FormCheckbox.module.scss";

const CheckboxList = ({
    formH,
    title,
    id,
    name,
    addName = "",
    list,
    defVal,
    activeAll = false,
    setChooseValue
                      }) => {
    return (
        <div>
            <div className={s.title}>{title}</div>
            {!!list?.length ? (
                <div className={s.list__wrapper}>
                    <ul>
                        {list.map((listItem, index) => {
                            const checked = !!defVal?.find(defItem => defItem.id === listItem.id);
                            return (
                                <li key={index}>
                                    <div className={s_FormSelect.checkbox__wrapper}>
                                        <input
                                            className={s_FormSelect.checkbox}
                                            type="checkbox"
                                            id={`${name}-${index}`}
                                            name={`${name}[${index}]${addName}`}
                                            value={listItem.id}
                                            checked={checked}
                                            onChange={(e) => {
                                                setChooseValue(prev => {
                                                    if(!!prev?.length) {
                                                        const temp = [...prev];
                                                        const included = temp?.findIndex(tempItem => tempItem.id === listItem.id);
                                                        if(included !== -1) {
                                                            temp.splice(included, 1);
                                                            return temp;
                                                        }
                                                        return [
                                                            ...temp,
                                                            {id: listItem.id, title: listItem.title_ua},
                                                        ]
                                                    }

                                                    return [{id: listItem.id, title: listItem.title_ua}];
                                                })
                                            }}
                                            ref={formH.register}
                                        />
                                        <label htmlFor={`${name}-${index}`} className={s_FormSelect.label}>{listItem.title_ua ? listItem.title_ua : listItem.title_ru}</label>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            ) : (
                <div>Даних немає</div>
            )}
        </div>
    );
};

export default CheckboxList;