import fetchAx from "../fetchAx";
import { MARKER_GET, SUBSCRIBERS_EVENTS_GET } from "../../const/api";
import { LIST_PER_PAGE } from "../../const/view";

export const getSubscribersEventsTypes = () => fetchAx({
    url: SUBSCRIBERS_EVENTS_GET,
    method: 'GET'
});

export const getSubscribersEventsWithParams = (params) => fetchAx({
    url: SUBSCRIBERS_EVENTS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        ...params,
    },
});