import React from "react";
import s from "./SliderMui.module.scss";
import Slider from "@mui/material/Slider";

const SliderMui = ({
                       min = 2,
                       max = 25,
                       valueLabelDisplay = 'auto',
                       defaultValue = 6,
                       value,
                       onChange,
                       customStyleBlack = true,
                       marks = false,
                       customStyleForMarks = false
}) => {
    return (
        <Slider
            defaultValue={defaultValue}
            aria-label="Default"
            valueLabelDisplay={valueLabelDisplay}
            min={min}
            max={max}
            value={value}
            color={'info'}
            marks={marks}
            onChange={onChange}
            className={customStyleBlack ? s.slider_black : ''}
            sx={customStyleForMarks && {
                '& .MuiSlider-mark': {
                    backgroundColor: '#9e9e9e',
                    width: 14,
                    height: 14,
                    borderRadius: '14px',
                    transform: 'translate(-50%, -50%)',
                },'& .MuiSlider-markActive': {
                    backgroundColor: 'black',
                    width: 14,
                    height: 14,
                    borderRadius: '14px',
                },
                '& .MuiSlider-markLabel': {
                    color: 'black',
                },
            }}
        />
    );
};

export default SliderMui;