import fetchAx from "../fetchAx";
import {QUIZZES_GET, SUBSCRIBERS_GET} from "../../const/api";
import {LIST_PER_PAGE} from "../../const/view";

export const getQuizzes = (params) => fetchAx({
    url: QUIZZES_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.default,
        page: 1,
        ...params,
    },
});