import React, {useEffect, useState} from 'react';
import s from './CheckboxSwitch.module.scss';
import cn from "classnames";

const CheckboxSwitch = ({ name, defaultChecked, id, label, fontWeight, onClickFunc, formH, styleLabel = {}, reverse = false, secondType = false }) => {
    const [active, setActive] = useState(false);
    const [actionWatch, setActionWatch] = useState(false);
    if(secondType) console.log(defaultChecked, 'defaultChecked', active)
    if(secondType){
        const actionListWatch = formH.watch('is_active_on_action_list');

        useEffect(() => {
            setActionWatch(actionListWatch);
            if(actionListWatch) setActive(true)
            if(defaultChecked) setActive(true);

        }, [defaultChecked, actionListWatch]);
    } else {
        useEffect(() => {
            if(defaultChecked) setActive(true);
        }, [defaultChecked]);
    }

    return (
        <div className={s.switch}>
            <label
                className={cn(s.switch__title, {
                    [s.active]: active,
                    [s.switch__title_reverse]: reverse
                })}
                htmlFor={id}
                style={{ fontWeight: fontWeight || 700, ...styleLabel }}
            >
                {label}
            </label>
            <div className={cn(s.checkbox, {
                [s.active]: active,
                [s.checkbox_reverse]: reverse
            })}>
                {(() => {
                    if (!secondType) {
                        return (
                            <input
                                id={id}
                                type="checkbox"
                                defaultChecked={defaultChecked}
                                name={name}
                                onClick={() => setActive((prev) => {
                                    if(onClickFunc) {
                                        onClickFunc(!prev);
                                    }
                                    return !prev;
                                })
                                }
                                ref={formH?.register}
                            />
                        );
                    } else {
                        return (
                            <input
                                id={id}
                                type="checkbox"
                                defaultChecked={defaultChecked}
                                checked={secondType && active}
                                name={name}
                                onClick={() => {
                                    if (!actionWatch) {
                                        setActive((prev) => !prev);
                                    }
                                }}
                                ref={formH?.register}
                            />
                        );
                    }
                })()}
            </div>
        </div>
    );
};

export default CheckboxSwitch;