import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import s_Modal from "./modal.module.scss";
import Button from "../../../components/ui/Button";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../components/ui/InputWrapper";
import cn from "classnames";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import { VALIDATION } from "../../../const/text";
import { createBrand, editBrand, getBrandEdit } from "../../../lib/api/brands";
import errorMessage from "../../../lib/errorMessage";

const ModalBrands = ({ active, setActive, title, editId }) => {
    const [load, setLoad] = useState(false);

    const formH = useForm({
        mode: 'onChange',
        defaultValues: {
            name: ''
        }
    });

    const {
        register,
        errors,
        handleSubmit,
        setValue
    } = formH;

    const submitHandler = async (data) => {
        if (editId) {
            await editBrand(editId, data).then((res) => {
                window.location.reload()
            }).catch(async (err) => await errorMessage(err, ''))
        } else {
            await createBrand(data).then((res) => {
                window.location.reload()
            }).catch(async (err) => await errorMessage(err, ''))
        }
    }

    useEffect(() => {
        if (editId) {
            setLoad(true);
            getBrandEdit(editId).then((res) => {
                setValue('name', res.data.name);
            })
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() => setLoad(false));
        }
    }, [editId])

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <form onSubmit={handleSubmit(submitHandler)}>
                    <div className={s_Modal.modal__header}>
                        <div>
                            <div className={s_Modal.modal__title}>{title ? title : 'Додати новий бренд'}</div>
                        </div>
                    </div>
                    <div>
                        {/* name */}
                        <Group attrs={{ style: { margin: 0 } }}>
                            <ColFull>
                                <InputWrapper
                                    label="Назва бренду"
                                    id="brand-name"
                                    errorComponent={errors?.name &&
                                    <p className="form-input-hint">{errors?.name?.message}</p>}
                                >
                                    <input
                                        className={cn(s_Input.input, {
                                            [s_Input.input_error]: errors?.name,
                                        })}
                                        type="text"
                                        placeholder="Назва бренду"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={register({
                                            required: VALIDATION.req.required
                                        })}
                                        name="name"
                                        id="brand-name"
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                    </div>
                    <div className={s_Modal.modal__footer}>
                        <div className={s_Modal.modal__group}>
                            <div className={s_Modal.modal__buttons}>
                                <div>
                                    <Button purple type='submit' disabled={load}>{editId ? 'Зберегти' : 'Додати'}</Button>
                                </div>
                            </div>

                            <div>
                                <Button border onClick={() => setActive(false)}>Скасувати</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ModalBrands;