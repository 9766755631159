import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import {getData, getFiltersData, remove} from "./api";
import getTZtoDate from "../../lib/getTZtoDate";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import s from "../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Table from "../OrderEditView/fakeData/orderTable.module.scss";

const GalaxyCafeList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        location,
        navigate,
        isMounted,
    });

    const ref = useRef(null);
    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = async() => {
        if (ref?.current?.checked){
            setSelectAll(true);
            const temp = rest?.data?.map(e => e.id);
            setSelected(temp);
        } else {
            setSelectAll(false);
            setSelected([]);
        }
    };

    const handleSelect = (id) => {
        const temp = [...selected];
        const index = temp.indexOf(id);

        if (index !== -1){
            temp.splice(index, 1);
            setSelected(temp);
        } else setSelected((prev) => ([...prev, id]));
    };

    useEffect(() => {
        if (!!selected?.length){
            localStorage.setItem('ids', JSON.stringify(selected));
        } else {
            localStorage.removeItem('ids');
        }
    }, [selected]);

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Quizzes list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable noSticky>
                <TableHead
                    arrayChildren={[
                        'Хеш', "Дата та час", 'Успіх', "Ім'я", 'Телефон', 'Email', "Купон", 'Автоматизація/Реєстрація', 'Каву видано', 'Мерч видано'
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {type: 'text', name: 'id'},
                            {type: 'date', name: 'created_at'},
                            {type: 'date', name: 'completed_at'},
                            {type: 'text', name: 'name'},
                            {type: 'text', name: 'phone'},
                            {type: 'text', name: 'email'},
                            {type: 'text', name: 'coupon_code'},
                            {type: 'date', name: 'authorized_at'},
                            {type: 'date', name: 'coffee_served_at'},
                            {type: 'date', name: 'merch_issued_at'},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                    showAction={false}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={8}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`quiz-item-${e.id}`}
                                    controlsOff
                                >
                                    <td>
                                        <span>
                                            {e?.id || '---'}
                                        </span>
                                    </td>
                                    <td>
                                       <span>
                                            {e?.created_at ? getTZtoDate(e?.created_at, true) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.completed_at ? getTZtoDate(e?.completed_at, true) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.phone || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.email || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.coupon_code || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.authorized_at ? getTZtoDate(e?.authorized_at, true) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.coffee_served_at ? getTZtoDate(e?.coffee_served_at, true) : '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.merch_issued_at ? getTZtoDate(e?.merch_issued_at, true) : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={8}>Немає підписок</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default GalaxyCafeList;
