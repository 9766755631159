import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {uid} from "react-uid";
import cn from "classnames";
import Group from "../FormEditView/Group";
import ColFull from "../FormEditView/ColFull";
import s from "./TreeCredit.module.scss";
import s_Select from "../FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Table from "../../../containers/OrderEditView/fakeData/orderTable.module.scss";
import s_Radio from "../InputRadio/InputRadio.module.scss";
import parseTree from "../../../lib/helpers/parseTree";
import s_Parent from "../../../containers/AccessoryRelationEditView/AccessoryRelation.module.scss";
import InputWrapper from "../InputWrapper";
import s_Input from "../InputWrapper/InputWrapper.module.scss";
import s_Cat from "../../../containers/AccessoryRelationEditView/components/CategoryWithProduct/CategoryWithProduct.module.scss";
import icon_search from "../../../assets/icons/icon_search.svg";
import s_OrderStyle from "../../../containers/OrderEditView/fakeData/orderStyle.module.scss";

const TreeCredit = ({
                        state,
                        action,
                        dataAll,
                        domainId,
                        formH,
                        checkAll = true,
                        typePriceProduct = false,
                        selectCategoryPriceType = false,
                        setDomainWatch
                    }) => {
    const ref = useRef();
    const refProduct = useRef();
    const [categoryData, setCategoryData] = useState([]);
    const [productOnData, setProductOnData] = useState([]);
    const [productOffData, setProductOffData] = useState([]);
    const [productShowName, setProductShowName] = useState('on');
    const [filterText, setFilterText] = useState('');

    const parseProductList = useCallback((array, type) => {
        let resultArray = [];
        switch (type) {
            case 'off': {
                resultArray = array.map(item => {
                    const filterArray = array.filter(itemFilter => itemFilter.category_id === item.category_id);
                    const mapArray = filterArray.map(item => {
                        const disable = state?.productsOnData?.includes(item?.id?.toString());

                        if (disable) {
                            return {
                                value: item.id,
                                category_id: item.category_id,
                                label: `${item?.title} (${item?.jde})`,
                                disabled: true,
                                domain_id: item.domain_id,
                            }
                        } else {
                            return {
                                value: item.id,
                                category_id: item.category_id,
                                label: `${item?.title} (${item?.jde})`,
                                domain_id: item.domain_id,
                            }
                        }
                    })

                    return ({
                        value: item.category_id,
                        label: item.category_id,
                        category_id: item.category_id,
                        children: mapArray,
                        domain_id: item.domain_id,
                    });
                })
                break;
            }
            case 'on': {
                resultArray = array.map(item => {
                    const filterArray = array.filter(itemFilter => itemFilter.category_id === item.category_id);
                    const mapArray = filterArray.map(item => {
                        const disable = state?.productsOffData?.includes(item?.id?.toString());
                        if (disable) {
                            return {
                                value: item.id,
                                category_id: item.category_id,
                                label: `${item?.title} (${item?.jde})`,
                                disabled: true,
                                domain_id: item.domain_id,
                            }
                        } else {
                            return {
                                value: item.id,
                                category_id: item.category_id,
                                label: `${item?.title} (${item?.jde})`,
                                domain_id: item.domain_id,
                            }
                        }
                    })

                    return ({
                        value: item.category_id,
                        label: item.category_id,
                        category_id: item.category_id,
                        children: mapArray,
                        domain_id: item.domain_id,
                    });
                })
                break;
            }
        }

        const removeDuplicates = (array, key) => {
            return array.reduce((arr, item) => {
                const removed = arr.filter(i => i[key] !== item[key]);
                return [...removed, item];
            }, []);
        };

        const result = removeDuplicates(resultArray, 'value').filter(item => item.value === state?.categorySelectShow);

        switch (type) {
            case 'on': {
                const addTitleCategory = result.map(ite => {
                    const filterCat = dataAll?.categoryAll.filter(item => item.domain_id === +domainId);
                    const findTitle = filterCat?.find(item => ite.value === item.id);

                    return ({label: findTitle?.title, value: uid(ite.value), children: ite.children, category_id: ite.category_id});
                });

                if (checkAll) {
                    return [{
                        value: uid('Выбрать все'),
                        label: "Выбрать все",
                        children: [...addTitleCategory],
                    }];
                }

                return addTitleCategory;

            }
            case 'off': {
                const addTitleCategory = result.map(ite => {
                    const filterCat = dataAll?.categoryAll.filter(item => item.domain_id === +domainId);
                    const findTitle = filterCat?.find(item => ite.value === item.id);
                    return ({label: findTitle?.title, value: uid(ite.value), children: ite.children, category_id: ite.category_id});
                });

                if (checkAll) {
                    return [{
                        value: uid('Выбрать все'),
                        label: "Выбрать все",
                        children: [...addTitleCategory],
                    }];
                }

                return addTitleCategory
            }
        }
    }, [dataAll, state?.productsList, state?.productsOffData, state?.productsOnData, productOnData, productOffData, state?.categorySelectShow]);

    const parseProductOn = useMemo(() => {
        if (!!state?.productsList?.length && (productShowName === 'on')) {
            return parseProductList(state?.productsList, 'on');
        }

        return [];
    }, [productOnData, state?.productsList, state?.productsOffData, state?.categorySelectShow, productShowName]);

    const parseProductOff = useMemo(() => {
        if (!!state?.productsList?.length && (productShowName === 'off')) {
            return parseProductList(state?.productsList, 'off');
        }

        return [];
    }, [productOffData, state?.productsList, state?.productsOnData, state?.categorySelectShow, productShowName]);

    const renderArrayWithChildren = useCallback((arr) => {
        if (!Array.isArray(arr)) {
            return '';
        }

        return arr.map(itemArray => {
            const { value, label, children, parent_id } = itemArray;
            let isActive = state?.categoriesID?.find(cat => cat === value);
            let indeterminateInput = false;

            if (!!children?.length && !!state?.categoriesID?.length) {
                const checkAllChecked = state?.categoriesID?.filter(item => {
                    const filterChildLength = children?.filter(itemChild => itemChild.value === item);
                    return !!filterChildLength?.length
                })

                if (checkAllChecked?.length > 0) {
                    indeterminateInput = true;
                }

                if ((checkAllChecked?.length === children?.length) && ref?.current) {
                    isActive = true;
                    indeterminateInput = false;
                    ref.current.indeterminate = false;
                }

                if (indeterminateInput && ref.current) {
                    ref.current.indeterminate = true;
                }
            }

            if ((ref?.current && !children?.length) && !isActive) {
                if (!!dataAll?.fields?.products_turn_on?.filter(prodOffItem => prodOffItem?.category_id === value)?.length) {
                    indeterminateInput = true;
                    ref.current.indeterminate = true;
                } else {
                    indeterminateInput = false;
                    ref.current.indeterminate = false;
                }
            }

            const copySpan = <span className={s.category__text}>
                            <div className={cn(s_Select.checkbox__wrapper, s.select_top, {
                                [s.input_hidden]: indeterminateInput
                            })}>
                              <input
                                  className={s_Select.checkbox}
                                  type="checkbox"
                                  id={`categories-${value}`}
                                  name={`categories`}
                                  defaultValue={value}
                                  checked={!!isActive}
                                  onChange={(e) => {
                                      if (selectCategoryPriceType) {
                                          action?.getCategoryPriceType(prev => {
                                              let temp = []

                                              if (!!prev?.length) {
                                                  temp = [...prev];
                                              }

                                              const findCatIndex = temp?.findIndex((cat) => cat?.category_id?.toString() === value?.toString());

                                              if (findCatIndex !== -1) {
                                                  temp.splice(findCatIndex, 1);

                                                  children?.map(e => {
                                                      const findChildrenIndex = temp?.findIndex((cat) => cat?.category_id?.toString() === e?.value?.toString());
                                                      temp.splice(findChildrenIndex, 1);
                                                  })
                                              } else {
                                                  temp.push({
                                                      category_id: +value,
                                                      price_type: "promo"
                                                  })

                                                  children?.map(e => {
                                                      temp.push({
                                                          category_id: +e.value,
                                                          price_type: "promo",
                                                          parent_id: +value,
                                                      })
                                                  })
                                              }

                                              if(parent_id) {
                                                  const findParent = categoryData?.filter(item => item.value === parent_id);

                                                  const checkAllChecked = state?.categoriesID?.filter(item => {
                                                      const filterChildLength = findParent[0]?.children?.filter(itemChild => itemChild.value === item);
                                                      return !!filterChildLength?.length
                                                  })

                                                  if(!!checkAllChecked?.length) {
                                                      const findCatIndex = temp?.findIndex((cat) => cat?.category_id?.toString() === parent_id?.toString());
                                                      if(findCatIndex !== -1) {
                                                          temp.splice(findCatIndex, 1);
                                                      }
                                                  }
                                              }

                                              return [...new Map(temp.map(item => [item.category_id, item])).values()];
                                          })

                                          // remove productOn if include category
                                          action?.getProductsOnPriceType(prev => {
                                              let temp = [];

                                              if (!!prev?.length) {
                                                  temp = prev.filter(productList => productList?.category_id?.toString() !== value?.toString());
                                              }

                                              return temp;
                                          })

                                          // remove productOff if not include category
                                          action?.getProductsOffPriceType(prev => {
                                              let temp = [];

                                              if (!!prev?.length) {
                                                  temp = [...prev];
                                              }
                                              if(isActive){
                                                  if (!!prev?.length) {
                                                      temp = prev.filter(productList => productList?.category_id?.toString() !== value?.toString());
                                                  }

                                              }

                                              return temp;

                                          })
                                      }
                                      action?.getCategoriesID(prev => {

                                          let temp = []

                                          if (!!prev?.length) {
                                              temp = [...prev];
                                          }

                                          const findCat = temp?.findIndex((cat) => cat?.toString() === e?.target?.value?.toString());

                                          if (!!children?.length) {
                                              const findUnCheckChild = children
                                                  .filter(childItem => !state?.categoriesID?.includes(childItem?.value));

                                              if (findUnCheckChild?.length === 0) {
                                                  children.forEach(childItem => {
                                                      const findIndex = temp?.findIndex(itemTemp => itemTemp === childItem?.value);

                                                      if (findIndex !== -1) {
                                                          temp?.splice(findIndex, 1);
                                                      }
                                                  });

                                                  const findCat1 = temp?.findIndex((cat) => cat?.toString() === e?.target?.value?.toString());
                                                  if(findCat1 !== -1) {
                                                      temp.splice(findCat1, 1);
                                                      if(temp?.includes(state?.categorySelectShow)) {
                                                          setProductShowName('off');
                                                      } else {
                                                          setProductShowName('on');
                                                      }

                                                      return temp;
                                                  }

                                                  return temp;
                                              }
                                              if(findCat !== -1) {
                                                  temp.splice(findCat, 1);
                                                  if(temp?.includes(state?.categorySelectShow)) {
                                                      setProductShowName('off');
                                                  } else {
                                                      setProductShowName('on');
                                                  }

                                                  return temp;
                                              }

                                              if (indeterminateInput) {
                                                  const parseCheckChild = findUnCheckChild?.map(childItem => childItem?.value);
                                                  return [...temp, ...parseCheckChild];
                                              } else {
                                                  const allValueChild = children?.map(childItem => childItem?.value);
                                                  return [...temp, ...allValueChild];
                                              }
                                          }
                                          const findCat3 = temp?.findIndex((cat) => cat?.toString() === e?.target?.value?.toString());

                                          if (findCat3 !== -1) {
                                              temp.splice(findCat3, 1);
                                              if (temp?.includes(state?.categorySelectShow)) {
                                                  setProductShowName('off');
                                              } else {
                                                  setProductShowName('on');
                                              }

                                              return temp;
                                          }

                                          if (+e?.target?.value === state?.categorySelectShow) {
                                              setProductShowName('off');
                                          } else {
                                              setProductShowName('on');
                                          }

                                          return [...temp, +e?.target?.value];
                                      })
                                  }}
                                  ref={ref}
                              />
                              <label
                                  htmlFor={`categories-${value}`}
                                  className={cn(`${s_Select.label} ${s_Table.label}`, {
                                      [s_Select.label_indeterminate]: indeterminateInput
                                  })}
                              />
                            </div>
                            <div className={cn('', {[s.category__text_active]: value === state?.categorySelectShow})} onClick={() => {
                                if(indeterminateInput) {
                                    if (state?.categoriesID?.includes(+value)) {
                                        setProductShowName('off');
                                    } else {
                                        setProductShowName('on');
                                    }
                                } else {
                                    if(isActive && state?.categoriesID?.includes(+value)) {
                                        if (state?.categoriesID?.includes(+value)) {
                                            setProductShowName('off');
                                        } else {
                                            setProductShowName('on');
                                        }
                                    } else {
                                        if (!!dataAll?.fields?.products_turn_off?.filter(prodOffItem => prodOffItem?.category_id === value)?.length) {
                                            setProductShowName('off');
                                        } else {
                                            setProductShowName('on');
                                        }
                                    }
                                }

                                action?.getCategoryShow(value);
                            }}>
                                {label}
                                <strong>
                                    {!!dataAll?.fields?.products_turn_off?.filter(prodOffItem => prodOffItem?.category_id === value)?.length && ` ${dataAll?.fields?.products_turn_off?.filter(prodOffItem => prodOffItem?.category_id === value)?.length} off`}
                                    {!!dataAll?.fields?.products_turn_on?.filter(prodOnItem => prodOnItem?.category_id === value)?.length && ` ${dataAll?.fields?.products_turn_on?.filter(prodOnItem => prodOnItem?.category_id === value)?.length} on`}
                                </strong>
                            </div>
                {selectCategoryPriceType && !!isActive && (
                    <div className={s_OrderStyle.select__inner} style={{ margin: '0 0 0 20px', minWidth: '115px' }}>
                        <div className={s_OrderStyle.select__wrapper} style={{ margin: 0 }}>
                            <select
                                name={`period[${value}]`}
                                className={s_OrderStyle.select}
                                id={`ad-period-${value}`}
                                defaultValue={state.categoryPriceType?.find(cat => cat?.category_id?.toString() === value?.toString())?.price_type || 'promo'}
                                onChange={(e) => {
                                    action?.getCategoryPriceType(prev => {
                                        let temp = []

                                        if (!!prev?.length) {
                                            temp = [...prev];
                                        }

                                        const findCat = temp?.findIndex((cat) => cat?.category_id?.toString() === value?.toString());

                                        if (findCat !== -1) {
                                            temp[findCat].price_type = e?.target?.value
                                        } else {
                                            temp.push({
                                                category_id: +value,
                                                price_type: e?.target?.value
                                            })
                                        }

                                        return temp;
                                    })
                                }}
                            >
                                <option value="promo">Промо</option>
                                <option value="rrp">РРЦ</option>
                            </select>
                            <label className={s_OrderStyle.label} htmlFor={`ad-period-${value}`}>Тип цены</label>
                        </div>
                    </div>
                )}
                        </span>

            if (Array.isArray(children)) {
                // If there are children, recursively render them
                const renderedChild = renderArrayWithChildren(children);

                return (
                    <ul className={s.category__wrapper} id={value} key={`tree-item-${value}`}>
                        {copySpan}
                        <ul className={s.category__wrapper}>{renderedChild}</ul>
                    </ul>
                );
            } else {
                // If no children, just render the current item
                return (
                    <ul className={s.category__wrapper} key={`tree-item-${value}`}>
                        <li>
                            {copySpan}
                        </li>
                    </ul>
                );
            }
        });
    }, [state?.categoriesID, state?.categorySelectShow, ref]);

    const renderArrayProductList = useCallback((arr, arrayCheck, parentId) => {
        if (!Array.isArray(arr)) {
            return '';
        }

        return arr.map(itemArray => {
            const {value, label, category_id, children, domain_id} = itemArray;
            let isActive = false;

            if ((typePriceProduct && (parentId === 'on' || parentId === 'off') && (typeof value === 'number'))) {
                isActive = arrayCheck?.findIndex(prod => prod?.id?.toString() === value?.toString() && prod?.domain_id?.toString() === domain_id?.toString()) !== -1;
            } else {
                if (!!arrayCheck?.length) {
                    isActive = arrayCheck?.findIndex(prod => prod?.toString() === value?.toString()) !== -1;
                }
            }
            let indeterminateInput = false;

            if (!!children?.length && !!arrayCheck?.length) {
                const checkAllChecked = arrayCheck?.filter(item => {
                    const filterChildLength = children?.filter(itemChild => itemChild.value?.toString() === item?.toString());
                    return !!filterChildLength?.length
                });

                if (checkAllChecked?.length > 0) {
                    indeterminateInput = true;
                }

                if (checkAllChecked?.length === children?.length) {
                    isActive = true;
                    indeterminateInput = false;
                    if (refProduct?.current) {
                        refProduct.current.indeterminate = false;
                    }
                }

                if (indeterminateInput && refProduct.current) {
                    refProduct.current.indeterminate = true;
                }
            }

            const copySpan = <span className={cn(s.category__text, {
                [s.hidden]: itemArray?.is_hidden
            })}>
                {(typePriceProduct && (parentId === 'on' || parentId === 'off') && (typeof value === 'number')) ? (
                    <>
                        <div className={cn(s_Select.checkbox__wrapper, s.select_top, {
                            [s.input_hidden]: indeterminateInput
                        })}>
                            <input
                                className={s_Select.checkbox}
                                type="checkbox"
                                id={`product-turn-${parentId}-${value}`}
                                name={`products_turn_${parentId}`}
                                data-domain={domain_id}
                                defaultValue={value}
                                checked={isActive}
                                onChange={(e) => {
                                    if (parentId === 'on') {
                                        action?.getProductsOnPriceType(prev => {
                                            let temp = [];

                                            if (!!prev?.length) {
                                                temp = [...prev];
                                            }

                                            const findProdIndex = temp?.findIndex((prod) => prod?.id?.toString() === e?.target?.value?.toString() && prod?.domain_id?.toString() === e?.target?.getAttribute('data-domain')?.toString());
                                            if (!!children?.length) {
                                                const findUnCheckChild = children
                                                    .filter(childItem => !arrayCheck?.includes(childItem?.value?.toString()));
                                                if (findUnCheckChild?.length === 0) {
                                                    children.forEach(childItem => {
                                                        const findIndex = temp?.findIndex(itemTemp => itemTemp?.id?.toString() === childItem?.value?.toString());

                                                        if (findIndex !== -1) {
                                                            temp?.splice(findIndex, 1);
                                                        }
                                                    });

                                                    return temp;
                                                }
                                                if (indeterminateInput) {
                                                    const parseCheckChild = findUnCheckChild?.map(childItem => ({
                                                        id: childItem?.value?.toString(),
                                                        price_type: 'promo'
                                                    }));
                                                    return [...temp, ...parseCheckChild];
                                                } else {
                                                    const allValueChild = children?.map(childItem => ({
                                                        id: childItem?.value?.toString(),
                                                        price_type: 'promo'
                                                    }));
                                                    return [...temp, ...allValueChild];
                                                }
                                            }

                                            if (findProdIndex !== -1) {
                                                temp.splice(findProdIndex, 1);
                                                return temp;
                                            }

                                            return [...temp, {
                                                id: e?.target?.value?.toString(),
                                                price_type: 'promo',
                                                category_id,
                                                domain_id
                                            }];
                                        })
                                    }
                                    if (parentId === 'off') {
                                        action?.getProductsOffPriceType(prev => {
                                            let temp = []

                                            if (!!prev?.length) {
                                                temp = [...prev];
                                            }

                                            const findProdIndex = temp?.findIndex((prod) => prod?.id?.toString() === e?.target?.value?.toString() && prod?.domain_id?.toString() === e?.target?.getAttribute('data-domain')?.toString());

                                            if (!!children?.length) {
                                                const findUnCheckChild = children
                                                    .filter(childItem => !arrayCheck?.includes(childItem?.value));
                                                if (findUnCheckChild?.length === 0) {
                                                    children.forEach(childItem => {
                                                        const findIndex = temp?.findIndex(itemTemp => itemTemp === childItem?.value);

                                                        if (findIndex !== -1) {
                                                            temp?.splice(findIndex, 1);
                                                        }
                                                    });

                                                    return temp;
                                                }
                                                if (indeterminateInput) {
                                                    const parseCheckChild = findUnCheckChild?.map(childItem => ({
                                                        id: childItem?.value?.toString(),
                                                    }));
                                                    return [...temp, ...parseCheckChild];
                                                } else {
                                                    const allValueChild = children?.map(childItem => ({
                                                        id: childItem?.value?.toString(),
                                                    }));
                                                    return [...temp, ...allValueChild];
                                                }
                                            }

                                            if (findProdIndex !== -1) {
                                                temp.splice(findProdIndex, 1);
                                                return temp;
                                            }

                                            return [...temp, {
                                                id: e?.target?.value?.toString(),
                                                price_type: 'promo',
                                                category_id,
                                                domain_id
                                            }];
                                        });

                                        action?.getCategoriesID(prev => {
                                            if (!!prev?.length) {
                                                const temp = [...prev];

                                                const findCat = temp?.findIndex((cat) => cat?.toString() === state?.categorySelectShow?.toString());

                                                if (findCat === -1) {
                                                    temp.push(+state?.categorySelectShow);
                                                }

                                                return temp;
                                            }

                                            return [+state?.categorySelectShow];
                                        })
                                    }

                                }}
                                ref={!!children?.length ? refProduct : null}
                            />
                            <label
                                htmlFor={`product-turn-${parentId}-${value}`}
                                className={cn(`${s_Select.label} ${s_Table.label}`, {
                                    [s_Select.label_indeterminate]: indeterminateInput
                                })}
                            />
                        </div>
                        <div>
                            {label}
                        </div>
                        {isActive && (
                            <div>
                                <select
                                    name={`products_turn_${parentId}_price_type[${value}].price_type`}
                                    className='form-select'
                                    id={`products-turn-select-item-price_type-${parentId}`}
                                    defaultValue={!!state?.productsOnPriceTypeData?.length
                                        ? state?.productsOnPriceTypeData?.find(itemProdOn => itemProdOn?.id?.toString() === value?.toString())?.price_type
                                        : 'promo'}
                                    onChange={(e) => {
                                        action?.getProductsOnPriceType(prev => {
                                            let temp = [];

                                            if (!!prev?.length) {
                                                temp = [...prev];
                                            }

                                            const findProdIndex = temp?.findIndex((prod) => prod?.id?.toString() === value?.toString());

                                            if (findProdIndex !== -1) {
                                                temp[findProdIndex].price_type = e?.target?.value;
                                                return temp;
                                            }
                                        })
                                    }}
                                >
                                    <option value="promo">Промо</option>
                                    <option value="rrp">РРЦ</option>
                                </select>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className={cn(s_Select.checkbox__wrapper, s.select_top, {
                            [s.input_hidden]: indeterminateInput
                        })}>
                            <input
                                className={s_Select.checkbox}
                                type="checkbox"
                                id={`product-turn-${parentId}-${value}`}
                                name={`products_turn_${parentId}`}
                                defaultValue={value}
                                checked={isActive}
                                onChange={(e) => {
                                    if (parentId === 'on') {
                                        action?.getProductsOnID(prev => {
                                            let temp = [];

                                            if (!!prev?.length) {
                                                temp = [...prev];
                                            }

                                            const findProdIndex = temp?.findIndex((prod) => prod?.toString() === e?.target?.value?.toString());
                                            if (!!children?.length) {
                                                const findUnCheckChild = children
                                                    .filter(childItem => !arrayCheck?.includes(childItem?.value?.toString()));
                                                if (findUnCheckChild?.length === 0) {
                                                    children.forEach(childItem => {
                                                        const findIndex = temp?.findIndex(itemTemp => itemTemp?.toString() === childItem?.value?.toString());

                                                        if (findIndex !== -1) {
                                                            temp?.splice(findIndex, 1);
                                                        }
                                                    });

                                                    return temp;
                                                }
                                                if (indeterminateInput) {
                                                    const parseCheckChild = findUnCheckChild?.map(childItem => childItem?.value?.toString());
                                                    return [...temp, ...parseCheckChild];
                                                } else {
                                                    const allValueChild = children?.map(childItem => childItem?.value?.toString());
                                                    return [...temp, ...allValueChild];
                                                }
                                            }

                                            if (findProdIndex !== -1) {
                                                temp.splice(findProdIndex, 1);
                                                return temp;
                                            }

                                            return [...temp, e?.target?.value?.toString()];
                                        })
                                    }
                                    if (parentId === 'off') {
                                        action?.getProductsOffID(prev => {
                                            let temp = []

                                            if (!!prev?.length) {
                                                temp = [...prev];
                                            }

                                            const findProdIndex = temp?.findIndex((prod) => prod?.toString() === e?.target?.value?.toString());

                                            if (!!children?.length) {
                                                const findUnCheckChild = children
                                                    .filter(childItem => !arrayCheck?.includes(childItem?.value));
                                                if (findUnCheckChild?.length === 0) {
                                                    children.forEach(childItem => {
                                                        const findIndex = temp?.findIndex(itemTemp => itemTemp === childItem?.value);

                                                        if (findIndex !== -1) {
                                                            temp?.splice(findIndex, 1);
                                                        }
                                                    });

                                                    return temp;
                                                }
                                                if (indeterminateInput) {
                                                    const parseCheckChild = findUnCheckChild?.map(childItem => childItem?.value);
                                                    return [...temp, ...parseCheckChild];
                                                } else {
                                                    const allValueChild = children?.map(childItem => childItem?.value);
                                                    return [...temp, ...allValueChild];
                                                }
                                            }

                                            if (findProdIndex !== -1) {
                                                temp.splice(findProdIndex, 1);
                                                return temp;
                                            }

                                            return [...temp, +e?.target?.value];
                                        });

                                        action?.getCategoriesID(prev => {
                                            if (!!prev?.length) {
                                                const temp = [...prev];

                                                const findCat = temp?.findIndex((cat) => cat?.toString() === state?.categorySelectShow?.toString());

                                                if (findCat === -1) {
                                                    temp.push(+state?.categorySelectShow);
                                                }

                                                return temp;
                                            }

                                            return [+state?.categorySelectShow];
                                        })
                                    }

                                }}
                                ref={!!children?.length ? refProduct : null}
                            />
                            <label

                            />
                        </div>
                        <div>
                            {label}
                        </div>
                    </>
                )}
            </span>

            if (Array.isArray(children)) {
                // If there are children, recursively render them
                const renderedChild = renderArrayProductList(children, arrayCheck, parentId);

                return (
                    <ul className={cn(s.category__wrapper, {
                        [s.margin0]: value?.toString()?.includes('val-')
                    })} id={value} key={`tree-item-${value}`}>
                        {copySpan}
                        <ul className={s.category__wrapper}>{renderedChild}</ul>
                    </ul>
                );
            } else {
                // If no children, just render the current item
                return (
                    <ul className={cn(s.category__wrapper, {
                        [s.margin0]: value?.toString()?.includes('val-')
                    })} key={`tree-product-${parentId}-item-${value}`}>
                        <li>
                            {copySpan}
                        </li>
                    </ul>
                );
            }
        });
    }, [state?.productsOnData, state?.productsOffData, state?.categorySelectShow, refProduct]);

    const filterProductTree = () => {
        if (productShowName === 'on') {
            if (!!filterText?.length) {
                setProductOnData(prev => {
                    let temp = [];

                    if (!!prev?.length) {
                        temp = [...prev];

                        if (!!temp?.[0]?.children?.length) {
                            if (!!temp?.[0]?.children?.[0]?.children?.length) {
                                const splitText = filterText?.split(',')
                                temp?.[0]?.children?.[0]?.children?.forEach((item, index) => {
                                    if (!!splitText?.length) {
                                        const filterValue = splitText?.filter(itemSplit => item?.label?.includes(itemSplit))
                                        if (!!filterValue?.length) {
                                            temp[0].children[0].children[index] = {
                                                ...temp?.[0]?.children?.[0]?.children?.[index],
                                                is_hidden: false
                                            }
                                        } else {
                                            temp[0].children[0].children[index] = {
                                                ...temp?.[0]?.children?.[0]?.children?.[index],
                                                is_hidden: true
                                            }
                                        }
                                    } else {
                                        if (!item?.label?.includes(filterText)) {
                                            temp[0].children[0].children[index] = {
                                                ...temp?.[0]?.children?.[0]?.children?.[index],
                                                is_hidden: true
                                            }
                                        } else {
                                            temp[0].children[0].children[index] = {
                                                ...temp?.[0]?.children?.[0]?.children?.[index],
                                                is_hidden: false
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    }
                    return temp;
                });
            } else {
                setProductOnData(prev => {
                    let temp = [];

                    if (!!prev?.length) {
                        temp = [...prev];

                        if (!!temp?.[0]?.children?.length) {
                            if (!!temp?.[0]?.children?.[0]?.children?.length) {
                                temp?.[0]?.children?.[0]?.children?.forEach((item, index) => {
                                    temp[0].children[0].children[index] = {
                                        ...temp?.[0]?.children?.[0]?.children?.[index],
                                        is_hidden: false
                                    }
                                })
                            }
                        }
                    }
                    return temp;
                });
            }
        }

        if (productShowName === 'off') {
            if (!!filterText?.length) {
                setProductOffData(prev => {
                    let temp = [];

                    if (!!prev?.length) {
                        temp = [...prev];

                        if (!!temp?.[0]?.children?.length) {
                            if (!!temp?.[0]?.children?.[0]?.children?.length) {
                                const splitText = filterText?.split(',')
                                temp?.[0]?.children?.[0]?.children?.forEach((item, index) => {
                                    if (!!splitText?.length) {
                                        const filterValue = splitText?.filter(itemSplit => item?.label?.includes(itemSplit))
                                        if (!!filterValue?.length) {
                                            temp[0].children[0].children[index] = {
                                                ...temp?.[0]?.children?.[0]?.children?.[index],
                                                is_hidden: false
                                            }
                                        } else {
                                            temp[0].children[0].children[index] = {
                                                ...temp?.[0]?.children?.[0]?.children?.[index],
                                                is_hidden: true
                                            }
                                        }
                                    } else {
                                        if (!item?.label?.includes(filterText)) {
                                            temp[0].children[0].children[index] = {
                                                ...temp?.[0]?.children?.[0]?.children?.[index],
                                                is_hidden: true
                                            }
                                        } else {
                                            temp[0].children[0].children[index] = {
                                                ...temp?.[0]?.children?.[0]?.children?.[index],
                                                is_hidden: false
                                            }
                                        }
                                    }
                                })
                            }
                        }
                    }
                    return temp;
                });
            } else {
                setProductOffData(prev => {
                    let temp = [];

                    if (!!prev?.length) {
                        temp = [...prev];

                        if (!!temp?.[0]?.children?.length) {
                            if (!!temp?.[0]?.children?.[0]?.children?.length) {
                                temp?.[0]?.children?.[0]?.children?.forEach((item, index) => {
                                    temp[0].children[0].children[index] = {
                                        ...temp?.[0]?.children?.[0]?.children?.[index],
                                        is_hidden: false
                                    }
                                })
                            }
                        }
                    }
                    return temp;
                });
            }
        }
    }

    const onEnterPress = (e) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();

            filterProductTree();
        }
    }

    const findProductOnSelect = (arrayFirst, arraySecond, categorySelectShow) => {
        const getValueFromArray = arrayFirst?.filter(item => {
            if(categorySelectShow === item?.category_id) return +item.id
        })

        return !!getValueFromArray?.length;
    }

    useEffect(() => {
        if (productOffData[0]?.children?.length !== 0) {
            setProductOffData(parseProductOff);
        }
    }, [state?.productsOnData]);

    useEffect(() => {
        if (productOnData[0]?.children?.length !== 0) {
            setProductOnData(parseProductOn);
        }
    }, [state?.productsOffData]);

    useEffect(() => {
        if(!!state?.categoriesList?.length) {
            setCategoryData(parseTree(state?.categoriesList, 'title', 'id', 'products_count', 'parent_id'));
        }
    }, [state?.categoriesList]);

    useEffect(() => {
        setProductOnData(parseProductOn);
        setProductOffData(parseProductOff);
    }, [state?.productsList, state?.categorySelectShow, productShowName]);

    return (
        <div>
            <div className={s.block}>
                {/* categories */}
                <div className={s.block__item}>
                    {setDomainWatch && (<div className={s_OrderStyle.additionalTabs} style={{margin: 0}}>
                        <div
                            className={s_OrderStyle.tabs__wrapper}
                            id="ad-domain-id"
                        >
                            {!!dataAll?.domains?.length && dataAll?.domains?.slice(0, 2).map(domain => (
                                <div
                                    key={`ad-domain-item-${domain.id}`}
                                    className={`${s_OrderStyle.default_tab} ${domainId === domain.id ? s_OrderStyle.active_tab : ''}`}
                                    onClick={() => setDomainWatch(domain.id)}
                                >
                                    {domain.title}
                                </div>
                            ))}
                        </div>
                        <input type="hidden" {...formH.register('domain_id')} />
                    </div>)}
                    <Group>
                        <ColFull>
                            <div>
                                <div className={s.title}>
                                    Категория
                                </div>
                                <div className={s.tree_wrap_category}>
                                    {!!categoryData?.length && (
                                        <ul className={s.category__wrapper}>
                                            {renderArrayWithChildren(categoryData)}
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </ColFull>
                    </Group>
                </div>

                <div className={s.block__item}>
                    <div style={{ display: 'flex', marginBottom: '16px' }}>
                        <div className={s_Radio.radio__wrapper} style={{ marginRight: '45px' }}>
                            <input
                                type="radio"
                                name="product_show"
                                id="change-product-show"
                                className={s_Radio.radio__input}
                                checked={productShowName === 'on'}
                                onChange={(e) => setProductShowName('on')}
                                disabled={state?.categoriesID?.includes(state?.categorySelectShow) || findProductOnSelect(state?.productsOffData, productOffData[0]?.children?.[0]?.children)}
                                value="on"
                            />
                            <label className={s_Radio.radio__label} htmlFor="change-product-show">On</label>
                        </div>

                        <div className={s_Radio.radio__wrapper}>
                            <input
                                type="radio"
                                name="product_show"
                                id="change-product-show"
                                className={s_Radio.radio__input}
                                checked={productShowName === 'off'}
                                onChange={(e) => setProductShowName('off')}
                                disabled={findProductOnSelect(state?.productsOnPriceTypeData, productOffData[0]?.children?.[0]?.children, state?.categorySelectShow)}
                                value="off"
                            />
                            <label className={s_Radio.radio__label} htmlFor="change-product-show">Off</label>
                        </div>
                    </div>

                    {state?.categorySelectShow ? (
                        <>
                            {/* products turn on */}
                            {productShowName === 'on' && state?.categorySelectShow && (
                                <div className={s.block__item} style={{ padding: 0 }}>
                                    <Group>
                                        <ColFull>
                                            <div>
                                                <div className={s.title}>
                                                    Включить товары
                                                </div>
                                                {!!productOnData[0]?.children?.length ? (
                                                    <>
                                                        <div className={s_Parent.mb20}>
                                                            <InputWrapper label="Поиск" id="search-prod">
                                                                <input
                                                                    className={s_Input.input}
                                                                    type="text"
                                                                    placeholder="Поиск"
                                                                    id="search-prod"
                                                                    value={filterText}
                                                                    onChange={(e) => setFilterText(e.target.value)}
                                                                    onKeyDown={onEnterPress}
                                                                />
                                                                <div className={s_Cat.input_icon} onClick={filterProductTree}>
                                                                    <img src={icon_search} alt="Icon search" />
                                                                </div>
                                                            </InputWrapper>
                                                        </div>
                                                        <div className={s.tree_wrap_product}>
                                                            {renderArrayProductList(productOnData[0]?.children, typePriceProduct ? state?.productsOnPriceTypeData : state?.productsOnData, "on")}
                                                        </div>
                                                    </>
                                                ) : <div>Нет товаров</div>}
                                            </div>
                                        </ColFull>
                                    </Group>
                                </div>
                            )}

                            {/* products turn off */}
                            {productShowName === 'off' && state?.categorySelectShow && (
                                <div className={s.block__item} style={{ padding: 0 }}>
                                    <Group>
                                        <ColFull>
                                            <div>
                                                <div className={s.title}>Исключить товары</div>
                                                {!!productOffData[0]?.children?.length ? (
                                                    <>
                                                        <div className={s_Parent.mb20}>
                                                            <InputWrapper label="Поиск" id="search-prod">
                                                                <input
                                                                    className={s_Input.input}
                                                                    type="text"
                                                                    placeholder="Поиск"
                                                                    id="search-prod"
                                                                    value={filterText}
                                                                    onChange={(e) => setFilterText(e.target.value)}
                                                                    onKeyDown={onEnterPress}
                                                                />
                                                                <div className={s_Cat.input_icon} onClick={filterProductTree}>
                                                                    <img src={icon_search} alt="Icon search"/>
                                                                </div>
                                                            </InputWrapper>
                                                        </div>
                                                        <div className={s.tree_wrap_product}>
                                                            {renderArrayProductList(productOffData[0]?.children, typePriceProduct ? state?.productsOffPriceTypeData : state?.productsOffData, "off")}
                                                        </div>
                                                    </>
                                                ) : <div>Нет товаров</div>}
                                            </div>
                                        </ColFull>
                                    </Group>
                                </div>
                            )}
                        </>
                    ) : (
                        <div>Выбирете категорию</div>
                    )}
                    {selectCategoryPriceType
                        && !!state?.categoryPriceType?.length
                        && state?.categoryPriceType?.map((cat, index) => (
                            <>
                                <input type="hidden" name={`categories_prices[${index}].category_id`} value={cat.category_id} ref={formH.register}/>
                                <input type="hidden" name={`categories_prices[${index}].price_type`} value={cat.price_type} ref={formH.register}/>
                            </>
                        ))}
                    {typePriceProduct ? !!state?.productsOnPriceTypeData?.length ? state?.productsOnPriceTypeData?.map((item, index) => (
                        <>
                            <input type="hidden" name={`products_turn_on[${index}].product_id`} value={item?.id} ref={formH.register} />
                            <input type="hidden" name={`products_turn_on[${index}].price_type`} value={item?.price_type} ref={formH.register} />
                            <input type="hidden" name={`products_turn_on[${index}].domain_id`} value={item?.domain_id} ref={formH.register} />
                        </>
                    )) : (
                        <> <input type="hidden" name="products_turn_on_price_type" value={state?.productsOnPriceTypeData} ref={formH.register} /></>
                    ) : <> <input type="hidden" name="products_turn_on" value={state?.productsOnData} ref={formH.register} />
                    </>}

                    {state?.productsOffPriceTypeData?.map((item, index) => (
                        <>
                            <input type="hidden" name={`products_turn_off[${index}].product_id`} value={item.id} ref={formH.register} />
                            <input type="hidden" name={`products_turn_off[${index}].domain_id`} value={item.domain_id} ref={formH.register} />
                            <input type="hidden" name={`products_turn_off[${index}].category_id`} value={item.category_id} ref={formH.register}/>
                        </>
                    ))}

                    {state?.categoriesID?.map((cat, index) => (
                        <>
                            <input type="hidden" name={`categories[${index}].category_id`} value={cat} ref={formH.register}/>
                            <input type="hidden" name={`categories[${index}].price_type`} value={'promo'} ref={formH.register}/>
                        </>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TreeCredit;