import { getEmailSubscription, getEmailSubscriptionSources } from "../../lib/api/email_subscription";
import { getSubscribersEventsTypes } from "../../lib/api/subscriber-events";

export const getFiltersData = () => Promise.allSettled([
    getSubscribersEventsTypes(),
])
    .then((res) => {
        return ({
            sources: res[0]?.value?.data,
        })
    });

export const getData = getEmailSubscription;