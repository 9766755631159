import {
    getSubscribers,
    removeSubscriber
} from '../../lib/api/subscribers';
import { getDomains } from "../../lib/api/domains";
import { getSubscribersEventsTypes } from "../../lib/api/subscriber-events";

export const getFiltersData = () => Promise.allSettled([
  getDomains(), getSubscribersEventsTypes(),
])
  .then((res) => ({
      domains: res[0]?.value?.data,
      types: res[1]?.value?.data,
  }));

export const getData = getSubscribers;
export const remove = removeSubscriber;

