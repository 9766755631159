import React from "react";
import s from "./Block.module.scss";

const Block = ({ title, children, style }) => {
    return (
        <div className={s.block} style={style}>
            <div className={s.block__title}>{title}</div>
            <div className={s.block__content}>
                {children}
            </div>
        </div>
    );
};

export default Block;