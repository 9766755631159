import { useLocation, useNavigate } from "react-router-dom";
import React from "react";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, getFiltersData } from "./api";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import getTZtoDate from "../../lib/getTZtoDate";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";

const SubscriberEvents = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Subscriptions list`}/>
            {!load && (
                <TopPagination
                    tabs={(
                        <TopNavTabs
                            tabs={[
                                {
                                    title: 'Підписки',
                                    url: '/app/subscribers',
                                    permissionName: 'admin.subscribers.index',
                                },
                                {
                                    title: 'Івенти',
                                    url: '/app/subscriber-types',
                                    permissionName: 'admin.subscriber-types.index',
                                    isActive: true
                                }
                            ]}
                        />
                    )}
                    // pagination={(
                    //     <>
                    //         {+rest?.meta?.total && (
                    //             <>
                    //                 <TableListViewPagination
                    //                     length={+rest?.meta?.last_page}
                    //                     current={+rest?.meta?.current_page}
                    //                     loc={location}
                    //                     navigate={navigate}
                    //                 />
                    //                 <TableListViewPerPage
                    //                     urlParams={urlParams}
                    //                     onSelect={(val, params) => pushUrlParams(params)}
                    //                     total={+rest?.meta?.total}
                    //                 />
                    //             </>
                    //         )}
                    //     </>
                    // )}
                />
            )}
            <TableListViewTable noSticky>
                <TableHead
                    arrayChildren={['ID', "Слаг", "Назва", "Дата створення"]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {type: 'text', name: 'id', attr: {style: {width: '75px'}}},
                            {type: 'text', name: 'slug'},
                            {type: 'text', name: 'name'},
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                    showAction={false}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={8}/>
                    )
                    : (rest && (
                        !!rest?.length
                            ? rest?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`subscriber-events-item-${e.id}`}
                                    controlsOff
                                >
                                    <td style={{ width: '100px'}}>
                                        <span>
                                            {e?.id || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.slug || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e.created_at ? getTZtoDate(e.created_at, true) : '---'}
                                        </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={8}>Немає підписок</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    );
};

export default SubscriberEvents;
