import React, { useEffect, useState } from 'react';
import Button from "../../../components/ui/Button";
import s_Modal from './modal.module.scss';
import errorMessage from "../../../lib/errorMessage";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import Group from "../../../components/ui/FormEditView/Group";
import {uid} from "react-uid";
import s_input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import {INPUT_MAX_LENGTH} from "../../../const/view";
import InputWrapper from "../../../components/ui/InputWrapper";
import getTZtoDate from "../../../lib/getTZtoDate";
import {editTryAndBuy, getTryAndBuyEdit} from "../../../lib/api/tryAndBuy";
import Loader from "../../../components/ui/Loader";
import s_OrderStyle from "../../OrderEditView/fakeData/orderStyle.module.scss";

const ModalEditTryAndBuy = ({ active, setActive, filtersData, activeId, title, rest, numOfDevices }) => {
    const [data, setData] = useState({});
    const [firstData, setFirstData] = useState({});
    const [dateMinDisable, setDateMinDisable] = useState(new Date());
    const [dateMaxDisable, setDateMaxDisable] = useState(new Date());
    const [load, setLoad] = useState(false);

    const sendData = async () => {
        if(data?.status !== 'waiting' && data?.status !== 'cancelled' && (!data?.device_id ) ){
            const err = {response: {status: 'customDocumentSelect'}}
            await errorMessage(err, 'Оберіть девайс')
        } else {
            const parseSendData = {
                "name": data?.name,
                "phone": data?.phone,
                "preferred_date": data?.preferred_date,
                "status": data?.status,
                "shop_id": +data?.shop_id,
                "created_at": data?.created_at,
                "settlement_id": data?.settlement_id,
                "device_id": (data?.device_id) ? +data?.device_id : null,
                "device_number": (data?.device_number) ? +data?.device_number : null,
            };

            await editTryAndBuy(activeId, parseSendData)
                .then((res) => window.location.reload())
                .catch(async (err) => await errorMessage(err, ''))
                .finally(() => setLoad(false));
        }
    };

    const handlerOnChange = (e) => {
        setData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.name === 'shop_id' ?  +e.target.value : e.target.value
            }
        })
    };

    const handlerOnChangeCity = (e) => {
        const selectedId = uniqueCities.filter(city => city?.settlement
            ?.id === +e.target.value)
        setData(prev => {
            return {
                ...prev,
                [e.target.name]: selectedId[0]?.settlement?.id
            }
        })
    };

    const handlerOnChangeDevice = (e) => {
        setData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
                device_number: filtersData?.devices?.filter(item=>item?.id === +e?.target?.value)[0]?.device_numbers[0] ? filtersData?.devices?.filter(item=>item?.id === +e?.target?.value)[0]?.device_numbers[0] : filtersData?.devices?.filter(item=>item?.id === +e?.target?.value && item?.id === firstData?.device_id) && firstData?.device_number
            }
        })
    };

    useEffect(() => {
        setLoad(true);
        getTryAndBuyEdit(activeId)
            .then((res) => {
                const parseRes = {
                    "name": res.data.name,
                    "phone": res.data.phone,
                    "preferred_date": res.data.preferred_date,
                    "status": res.data.status,
                    "device_id": res.data.device_id,
                    "shop_id": res.data.shop_id,
                    "created_at": res.data.created_at,
                    "settlement_id": res.data.settlement_id,
                    "device_number": res.data.device_number,
                    "first_load": true,
                };

                setData(parseRes);
                setFirstData(parseRes);
            })
            .catch(async (err) => await errorMessage(err, ''))
            .finally(() => setLoad(false));
    }, []);

    useEffect(() => {
        const parseDate = getTZtoDate(dateMinDisable);
        const parseDateMax = getTZtoDate(dateMaxDisable);

        const parseDateMin = (date, addDate) => {
            let getDay = Number(date.split('.')[0]) + addDate;
            let getMouth = Number(date.split('.')[1]);
            const getYear = date.split('.')[2];
            if(getMouth < 10) {
                getMouth = '0' + getMouth.toString();
            }
            if(getDay < 10) {
                getDay = '0' + getDay.toString();
            }
            return `${getYear}-${getMouth}-${getDay}`;
        };

        setDateMinDisable(parseDateMin(parseDate, 2));
        setDateMaxDisable(parseDateMin(parseDateMax, 4));
    }, []);

    const copyCities = filtersData?.shops
    const uniqueCities = copyCities?.filter((shop, index, self) =>
        index === self.findIndex((s) => s.settlement?.id === shop.settlement?.id)
    );

    const copySelectedCity = filtersData?.shops
    const selectedCity = copySelectedCity?.filter((shop) => {
        if (shop?.id === data?.shop_id){
            return uniqueCities.find((el) => el?.settlement?.title === shop?.settlement?.title);
        }
        return false;
    });

    const copyShops = filtersData?.shops
    const uniqueShops = copyShops?.filter((shop, index) => {
        if(data?.settlement_id){
            return shop?.settlement?.id === data?.settlement_id
        } else {
            return shop?.settlement?.title === selectedCity[0]?.settlement?.title
        }
    });

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
                style={{minWidth: "663px"}}
            >
                {!!Object.keys(data)?.length ? (
                    <>
                        <div className={s_Modal.modal__header}>
                            <div>{getTZtoDate(data?.created_at, true)}</div>
                            <div>{data?.name} {data?.phone}</div>
                            <div className={s_Modal.modal__title}>{title}</div>

                        </div>
                        <div className={s_Modal.modal__box} style={{ maxHeight: "100vh"}} >
                            {/* settlement */}
                            <Group attrs={{className: 'mb-1'}}>
                                <ColFull>
                                    <div className={s_OrderStyle.select__inner}>
                                        <div className={s_OrderStyle.select__wrapper}>
                                            <select
                                                name="settlement_id"
                                                className={s_OrderStyle.select}
                                                defaultValue={selectedCity && selectedCity[0]?.settlement?.id}
                                                id="settlement-id"
                                                onChange={handlerOnChangeCity}
                                            >
                                                <option value="">---</option>
                                                {filtersData?.shops && uniqueCities.map((e) => (
                                                    <option
                                                        value={e?.settlement?.id}
                                                        key={uid(e?.settlement?.id)}
                                                    >
                                                        {e?.settlement?.title}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={s_OrderStyle.label} htmlFor={`mtab-settlement-id`}>Місто</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>
                            <Group attrs={{className: 'mb-1'}}>
                                <ColFull>
                                    <div className={s_OrderStyle.select__inner}>
                                        <div className={s_OrderStyle.select__wrapper}>
                                            <select
                                                name="shop_id"
                                                className={s_OrderStyle.select}
                                                defaultValue={selectedCity && selectedCity[0]?.id}
                                                onChange={handlerOnChange}
                                            >
                                                <option value="">---</option>
                                                {filtersData?.shops && uniqueShops?.map((e) => (
                                                    <option
                                                        value={e?.id}
                                                        key={uid(e)}
                                                    >
                                                        {e?.title}
                                                    </option>
                                                ))}
                                            </select>
                                            <label className={s_OrderStyle.label} htmlFor={`mtab-shop-id`}>Магазин</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>
                            <Group attrs={{className: 'mb-1'}}>
                                <ColFull>
                                    <InputWrapper
                                        label="Желаемая дата"
                                        id={`ps-preferred-date`}
                                    >
                                        <input
                                            className={s_input.input}
                                            type="date"
                                            placeholder="Желаемая дата"
                                            maxLength={INPUT_MAX_LENGTH}
                                            name="preferred_date"
                                            id={`ps-preferred-date`}
                                            min={dateMinDisable}
                                            max={dateMaxDisable}
                                            value={data["preferred_date"]}
                                            onChange={handlerOnChange}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>
                            <Group attrs={{className: 'mb-1'}}>
                                <ColFull>
                                    <div className={s_OrderStyle.select__inner}>
                                        <div className={s_OrderStyle.select__wrapper}>
                                            <select
                                                name="device_id"
                                                className={s_OrderStyle.select}
                                                defaultValue={data && data["device_id"] ? data["device_id"] : ''}
                                                onChange={handlerOnChangeDevice}
                                                disabled={numOfDevices === 0}
                                            >
                                                {(data && (!data["device_id"] || !data["device_number"]) || data['status'] === "waiting" || data['status'] === "cancelled" ) && <option value="">---</option>}
                                                {numOfDevices === 0 && data["device_id"] && <option value="">{filtersData?.devices?.filter(el => el.id === data?.device_id)[0]?.title}</option>}
                                                {filtersData?.devices && filtersData?.devices?.map((e) => {
                                                    if(e?.is_available) {
                                                        return (
                                                            <option
                                                                value={e?.id}
                                                                key={uid(e)}
                                                                disabled={!e?.is_available}
                                                            >
                                                                {e?.title}
                                                            </option>
                                                        )
                                                    }
                                                })}
                                            </select>
                                            <label className={s_OrderStyle.label} htmlFor={`mtab-device-id`}>Девайс</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>
                            <Group attrs={{className: 'mb-1'}}>
                                <ColFull>
                                    <div className={s_OrderStyle.select__inner}>
                                        <div className={s_OrderStyle.select__wrapper}>
                                            <select
                                                name="status"
                                                className={s_OrderStyle.select}
                                                defaultValue={data && data["status"]}
                                                onChange={handlerOnChange}
                                            >
                                                <option value="">---</option>
                                                {filtersData?.status && filtersData.status.map((e) => (
                                                    firstData["status"] === 'cancelled' ? (
                                                        e?.value === 'waiting' || e?.value === 'cancelled' ? (
                                                            <option
                                                                value={e?.value}
                                                                key={uid(e)}
                                                            >
                                                                {e?.title}
                                                            </option>
                                                        ) : null
                                                    ) : firstData["status"] === 'waiting' ? (
                                                        e?.value !== 'done' && e?.value !== 'new'? (
                                                            <option
                                                                value={e?.value}
                                                                key={uid(e)}
                                                            >
                                                                {e?.title}
                                                            </option>
                                                        ) : null
                                                    ) : (
                                                        <option
                                                            value={e?.value}
                                                            key={uid(e)}
                                                        >
                                                            {e?.title}
                                                        </option>
                                                    )
                                                ))}

                                            </select>
                                            <label className={s_OrderStyle.label} htmlFor={`mtab-status`}>Статус</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>
                        </div>
                    </>
                ) : load && <Loader />}

                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group}>
                        <div>
                            <Button purple onClick={sendData}>Применить</Button>
                        </div>
                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalEditTryAndBuy;