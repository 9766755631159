import fetchAx from '../fetchAx';
import {
  PAYMENTS_GET,
  PAYMENT_GET_EDIT,
  PAYMENT_EDIT,
} from '../../const/api';
import {LIST_PER_PAGE} from "../../const/view";

export const getPayments = (params) => fetchAx({
  url: PAYMENTS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.xxl,
    ...params,
  },
});

export const getPaymentEdit = (id) => fetchAx({
  url: PAYMENT_GET_EDIT.join(id),
  method: 'GET',
});

export const editPayment = (id, data) => fetchAx({
  url: PAYMENT_EDIT.join(id),
  method: 'PUT',
  data,
});

