import React, { useEffect, useState } from 'react';
import { getDomains } from '../../../lib/api/domains';
import { editMedia, getMediaTypes } from "../../../lib/api/media";
import s_Modal from './modal.module.scss';
import Button from "../../../components/ui/Button";
import { toast } from "react-toastify";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../../components/ui/InputWrapper";
import s_Input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import errorMessage from "../../../lib/errorMessage";

const ModalMedia = ({ active, setActive, title, data, setImageData }) => {
    const [domains, setDomains] = useState([]);
    const [sendData, setSendData] = useState({
        collection_name: 'cover',
        custom_properties: {
            alt_ru: '',
            alt_ua: '',
        },
        is_active: true,
    });

    const [load, setLoad] = useState(false);

    const handleSendData = async () => {
        setLoad(true);
        await editMedia(data?.id, sendData).then((res) => {
            toast('Картинка обновлена', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setImageData(prev => {
                const temp = [...prev];
                const findIndex = temp.findIndex((item) => item.id === res?.data?.id);
                temp[findIndex] = res.data;
                return temp;
            });
            setActive(false);
        })
            .catch(async (err) => await errorMessage(err , ''))
            .finally(() => setLoad(false));
    }

    const onChange = (key, value) => {
        setSendData(prev => ({
            ...prev,
            [key]: value
        }))
    };

    useEffect(() => {
        getDomains().then(res => {
            setDomains(res.data);
        }).catch(async (err) => await errorMessage(err , ''));
    }, []);

    useEffect(() => {

        if(data?.custom_properties?.alt_ru) {
            setSendData(prev => ({
                ...prev,
                custom_properties: {
                    ...prev?.custom_properties,
                    'alt_ru': data?.custom_properties?.alt_ru
                }
            }));
        }

        if(data?.custom_properties?.alt_ua) {
            setSendData(prev => ({
                ...prev,
                custom_properties: {
                    ...prev?.custom_properties,
                    'alt_ua': data?.custom_properties?.alt_ua
                }
            }));
        }

        if(data) {
            setSendData(prev => ({
                ...prev,
                'is_active': !!data?.is_active,
                'domain_id': data?.domain_id
            }));
        }
    }, [data]);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div className={s_Modal.modal__title}>{title}</div>
                </div>
                <div className={s_Modal.modal__box}>

                    {/* domain_id */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    <select
                                        name="domain_id"
                                        className={s_Select.select}
                                        id="m-domain"
                                        onChange={(e) => onChange('domain_id', e.target.value)}
                                    >
                                        <option value={null}>выберите домен</option>
                                        {!!domains?.length && domains?.map((domain) => (
                                            <option
                                                key={domain.id}
                                                value={domain.id}
                                                selected={domain?.id?.toString() === data?.domain_id?.toString()}
                                            >
                                                {domain.title}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={s_Select.label} htmlFor="m-domain">Домен</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* alt_ru */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <InputWrapper label="ALT RU" id="m-alt-ru">
                                <input
                                    className={s_Input.input}
                                    type="text"
                                    placeholder="ALT RU"
                                    name="custom_properties.alt_ru"
                                    id="m-alt-ru"
                                    value={sendData?.custom_properties?.alt_ru}
                                    onChange={(e) => setSendData(prev => ({
                                        ...prev,
                                        custom_properties: {
                                            ...prev?.custom_properties,
                                            alt_ru: e.target.value
                                        }
                                    }))}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* alt_ua */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <InputWrapper label="ALT UA" id="m-alt-ua">
                                <input
                                    className={s_Input.input}
                                    type="text"
                                    placeholder="ALT UA"
                                    name="custom_properties.alt_ua"
                                    id="m-alt-ua"
                                    value={sendData?.custom_properties?.alt_ua}
                                    onChange={(e) => setSendData(prev => ({
                                        ...prev,
                                        custom_properties: {
                                            ...prev?.custom_properties,
                                            alt_ua: e.target.value
                                        }
                                    }))}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* is_active */}
                    <Group attrs={{className: 'mt-2 mb-2'}}>
                        <ColFull>
                            <label className="form-switch">
                                <input
                                    name="is_active"
                                    type="checkbox"
                                    id="m-is-active"
                                    checked={sendData?.is_active}
                                    onChange={(e) => setSendData(prev => ({
                                        ...prev,
                                        'is_active': e.target.checked
                                    }))}
                                />
                                <i className="form-icon" />
                                {' '}
                                Статус активности
                            </label>
                        </ColFull>
                    </Group>
                </div>
                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group}>
                        <div>
                            <Button purple onClick={handleSendData} disabled={load}>Сохранить</Button>
                        </div>
                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMedia;