import {
    getSortCategories,
    removeSortCategories,
    editSortCategories, editSortBankSelections
} from "../../lib/api/sort";
import {getDomains, getSelectionsAll} from "../../lib/api/domains";
import {getBanks, removeBankSelection} from "../../lib/api/banks";

export const getFiltersData = () => Promise.allSettled([
    getDomains(), getBanks(), getSelectionsAll()
])
    .then((res) => ({
        domains: res[0]?.value?.data,
        banks: res[1]?.value?.data,
        selections: res[2]?.value?.data,
    }));

export const edit = editSortBankSelections;
export const getData = getSortCategories;
export const remove = removeBankSelection;
