export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.image-absolute-title-right-native {
                position: relative;
            }
            div.h2-custom-absolute-right {
                position: absolute;
                right: 0;
                top: 40px;
                margin-right: 40px;
                width: 50%;
            }
            div.small-custom-absolute-right {
                width: 100%;
                margin-right: 40px;
                overflow-wrap: break-word;
                text-align: right;
                position: absolute;
                bottom: 0;
                right: 0;
            }
            div.h2-custom-absolute-right > h2 {
                display: block;
                text-align: right;
            }
            div.h2-custom-absolute-right > h2 > span {
                font-weight: 700;
                font-size: 50px !important;
                line-height: 65px !important;
                display: block;
                text-align: right;
                overflow-wrap: break-word;
            }
            
            div.desc_text_custom-right {
                display: block;
                text-align: right;
            }
            
            div.desc_text_custom-right > span {
                font-size: 18px;
                line-height: 24px;
                text-align: right;
                display: block;
                overflow-wrap: break-word;
            }
            
            @media screen and (max-width: 992px) {
                div.h2-custom-absolute-right {
                    top: 20px;
                    margin-right: 20px;
                }
                div.small-custom-absolute-right {
                    margin-right: 20px;
                }
                div.h2-custom-absolute-right > h2 > span {
                    font-size: 36px !important;
                    line-height: 40px !important;
                }
            }
            
            @media screen and (max-width: 769px) {
                div.h2-custom-absolute-right {
                    position: static;
                    margin-right: 20px;
                    width: auto;
                }
                div.small-custom-absolute-right {
                    position: static;
                    margin-right: 20px;
                    width: auto;
                }
            }
            
            @media screen and (max-width: 480px) {
                div.h2-custom-absolute-right {
                    margin-right: 10px;
                }
                div.small-custom-absolute-right {
                    margin-right: 10px;
                }
                /*div.h2-custom-absolute-right > h2 > span {*/
                /*    font-size: 15px !important;*/
                /*    line-height: 20px !important;*/
                /*}*/
                div.desc_text_custom-right > span {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
                <div class="image-absolute-title-right-native">
                    <div class="h2-custom-absolute-right">
                        <h2><span>Заголовок H2</span></h2>
                        <div class="desc_text_custom-right mb-2 mb-lg-4"><span>Ваше описание</span></div>
                    </div>
                    <div class="small-custom-absolute-right">
                        <div class="small_text_custom-right mb-2 mb-lg-4"><span>Ваш текст</span></div>
                    </div>
                    <img src="https://via.placeholder.com/1530x680" alt="" />
                </div>
                ${style}
        `,
    })
};