import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {Helmet} from "react-helmet";
import {DEVELOPMENT_ENVIRONMENT, DOMAIN_API} from "../../const/api";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import {edit, getData, getFiltersData, remove} from "./api";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import checkPermission from "../../lib/checkPermission";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import TopPanelNew from "../../components/common/TopPanelNew";
import errorMessage from "../../lib/errorMessage";
import {refreshSortSpecialOffers, updateSortSpecialOffers} from "../../lib/api/sort";
import Button from "../../components/ui/Button";
import icon_save from "../../assets/icons/icon_save.svg";
import {toast} from "react-toastify";

const SortSpecOffersList = () => {
    const [selectedSort, setSelectedSort] = useState([]);
    const [sortArray, setSortArray] = useState([1]);
    const [currentCard, setCurrentCard] = useState(null);

    const params = new URL(window.location.href).searchParams;
    const domain_id = params.get('domain_id')

    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        setLoad,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
        filters: {
            domain_id: domain_id ? domain_id : '1'
        }
    });

    const handleSelectSort = (e, value) => {
        setSelectedSort(prev => {
            const temp = [...prev];

            const parseItem = {
                id: e?.id,
                position: +value
            };

            if (!!temp?.length) {
                const findSortIndex = temp.findIndex(itemSelect => itemSelect.id === parseItem.id)

                if (findSortIndex !== -1) {
                    temp[findSortIndex] = parseItem;
                    return temp;
                }
                temp.push(parseItem);
            } else {
                temp.push(parseItem);
            }

            return temp;
        })
    }

    const handleUpdateSort = async () => {
        setLoad(true);
        await updateSortSpecialOffers({
            domain_id: urlParams?.['domain_id'] || '1',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page,
            positions: selectedSort
        })
            .then((res) => {
                setSelectedSort([]);
                setRest(prev => {
                    const temp = {...prev};

                    temp.data = res.data?.data;

                    return temp;
                })
            })
            .catch(err => errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    const handleRefreshSort = async () => {
        setLoad(true);
        await refreshSortSpecialOffers({
            domain_id: urlParams?.['domain_id'] || '1',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page,
        })
            .then((res) => {
                setRest(prev => {
                    const temp = {...prev};
                    temp.data = res.data?.data;
                    return temp;
                });
                toast('Рефреш виконано', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
            .catch(err => errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    useEffect(() => {
        if (rest?.meta?.total) {
            const totalArray = [...Array(rest?.meta?.total + 1).keys()].filter(item => !!item);

            if (!!rest?.data?.length) {
                rest?.data?.forEach(item => {
                    if (!totalArray?.includes(item?.sort)) {
                        totalArray.push(item?.sort)
                    }
                })
            }
            setSortArray(totalArray);
        }
    }, [rest?.meta?.total, rest?.data]);

    const dropHandler = async (e, card) => {
        e.preventDefault()

        const id = currentCard.product_id ? currentCard.product_id : currentCard.id
        const index = card.manual_sort ? card.manual_sort : card.sort

        await edit({
            domain_id: urlParams?.['domain_id'] || '1',
            page: rest?.meta?.current_page,
            per_page: rest?.meta?.per_page,
        })(id, index).then((res) => setRest(prev => {
            const temp = {...prev};
            temp.data = res.data?.data;

            return temp;
        }))
    }

    return (
        <>
            <TopPanelNew
                title='Сортування'
                breadcrumbs={{
                    items: [
                        {
                            title: 'Головна',
                            url: '/app',
                            permissionName: 'admin.sort.special-offers',
                        },
                        {
                            title: 'Сортування',
                            url: `/app/sort/special-offers${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                            permissionName: 'admin.sort.special-offers',
                        },
                    ],
                }}
                customButtonTop={checkPermission('admin.sort.special-offers.update')
                    ? (
                        <>
                            <Button onClick={handleUpdateSort} disabled={!selectedSort?.length} purple>
                                <img src={icon_save} alt="icon save"/>
                                Зберегти / Сортувати
                            </Button>
                        </>
                    ) : null
                }
                positionSticky
            />

            <div>
                <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Sort special offers list`}/>
                {!load && (
                    <TopPagination
                        tabs={(
                            <TopNavTabs tabs={[
                                {
                                    title: 'Товари',
                                    url: '/app/sort/products',
                                    permissionName: 'admin.sort.products'
                                },
                                {
                                    title: 'Банера',
                                    url: '/app/sort/banner/catalog',
                                    permissionName: 'admin.sort.banner-catalog'
                                },
                                {
                                    title: 'Акції',
                                    url: '/app/sort/special-offers',
                                    permissionName: 'admin.sort.special-offers',
                                    isActive: true
                                },
                                {
                                    title: 'Категорії супутніх товарів',
                                    url: '/app/sort/categories',
                                    permissionName: 'admin.sort.category-sort'
                                },
                                {
                                    title: 'Банки ОЧ',
                                    url: '/app/sort/banks-pay-part?filter=1',
                                    permissionName: 'admin.sort.banks-pay-part',
                                }
                            ]}
                            />
                        )}
                        pagination={(
                            <>
                                {rest?.meta?.total ? (
                                    <>
                                        <TableListViewPagination
                                            length={+rest?.meta?.last_page}
                                            current={+rest?.meta?.current_page}
                                            loc={location}
                                            navigate={navigate}
                                        />
                                        <TableListViewPerPage
                                            urlParams={urlParams}
                                            onSelect={(val, params) => pushUrlParams(params)}
                                            total={+rest?.meta?.total}
                                        />
                                    </>
                                ) : null}
                            </>
                        )}
                        stickySecond
                    />
                )}
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 28}}>
                    <div style={{display: 'flex', gap: '24px'}}>
                        <div className={s_Select.select__inner}>
                            <div className={s_Select.select__wrapper}>
                                {!!filtersData?.domains?.length
                                    ? (
                                        <select
                                            name="domain_id"
                                            className={s_Select.select}
                                            id="b-domain"
                                            onChange={(e) => pushUrlParams({
                                                ...urlParams,
                                                domain_id: e.target.value
                                            })}
                                        >
                                            {!!filtersData?.domains?.length && filtersData?.domains.map((domain) => (
                                                <option
                                                    key={domain.id}
                                                    value={domain.id}
                                                    selected={domain.id?.toString() === urlParams?.domain_id}
                                                >
                                                    {domain.title}
                                                </option>
                                            ))}
                                        </select>
                                    )
                                    : (
                                        <div className="p-relative">
                                            <div className="loading"/>
                                        </div>
                                    )}
                                <label className={s_Select.label} htmlFor="b-domain">Домен</label>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: checkPermission('admin.sort.special-offers.refresh')
                            ? 'flex'
                            : 'none',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: '16px'
                    }}>
                        <Button onClick={handleRefreshSort} purple>
                            Рефреш сортування
                        </Button>
                    </div>
                </div>
                <TableListViewTable>
                    <TableHead
                        arrayChildren={[
                            'ID', 'Назва товару УКР', 'Назва товару РУС',
                            'Зображення', 'Позиція', 'Статус', 'Прихована/Не прихована'
                        ]}
                        filterChildren={<TableListViewFiltersRow
                            urlParams={urlParams}
                            fields={[
                                {},
                                {type: 'text', name: 'title_ua'},
                                {type: 'text', name: 'title_ru'},
                                {},
                                {},
                                {
                                    type: 'select', name: 'is_active', options: [
                                        {
                                            id: 0,
                                            title: 'Вимкнено',
                                        },
                                        {
                                            id: 1,
                                            title: 'Активна'
                                        }
                                    ]
                                },
                                {},
                                {}
                            ]}
                            onSubmit={pushUrlParams}
                        />}
                    />
                    <tbody>
                    {load
                        ? (
                            <TableListViewRowLoader cols={7}/>
                        )
                        : (rest && (
                            rest?.data?.length
                                ? rest?.data?.map((e, i) => (
                                    <TableListViewRow
                                        id={e.id}
                                        key={`sort-special-offers-item-${e.id}`}
                                        remove={{
                                            api: remove({
                                                domain_id: urlParams?.['domain_id'],
                                            }),
                                            success: () => setRest(getSplicedData(i, rest)),
                                            alert: 'Сортування акції видалено',
                                        }}
                                        permissionRemove='admin.sort.special-offers.delete'
                                        card={e}
                                        setCurrentCard={setCurrentCard}
                                        drop={dropHandler}
                                        semiPageType={e?.parent_id !== null}
                                        semiPageValue={true}
                                    >
                                        <td style={e?.parent_id !== null ? {background: "#0000ff1a"} : {}}>
                                            {checkPermission('admin.special-offer_banner.index') ? (
                                                <a href={`${DOMAIN_API}/app/spec-offer-banner/update/${e?.id}`}>{e?.id}</a>
                                            ) : (
                                                <span>
                                                    {e?.id || '---'}
                                                </span>
                                            )}
                                        </td>
                                        <td style={e?.parent_id !== null ? {background: `#0000ff1a`} : {}}>
                                            <span>
                                                {e?.title_ua || '---'}
                                            </span>
                                        </td>
                                        <td style={e?.parent_id !== null ? {background: "#0000ff1a"} : {}}>
                                            <span>
                                                {e?.title_ru || '---'}
                                            </span>
                                        </td>
                                        <td style={e?.parent_id !== null ? {background: "#0000ff1a"} : {}}>{e?.cover_ua ? <img src={e?.cover_ua?.original || e?.cover_ua?.conversions?.original} alt=""/> : "---"}</td>
                                        <td style={e?.parent_id !== null ? {background: "#0000ff1a"} : {}}>
                                            <select
                                                name={`sort-name-${e.id}`}
                                                className="form-select"
                                                onChange={(event) => handleSelectSort(e, event.target.value)}
                                                defaultValue={e?.sort}
                                            >
                                                {sortArray.map((item) => (
                                                    <option value={item} key={`sort-name-${item}`}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                        <td style={e?.parent_id !== null ? {background: "#0000ff1a"} : {}}>
                                            <span>
                                                {e.is_active ? <StatusCircle green text="Активна"/> :
                                                    <StatusCircle red text="Вимкнено"/>}
                                            </span>
                                        </td>
                                        <td style={e?.parent_id !== null ? {background: "#0000ff1a"} : {}}>
                                            <span>
                                                {e.is_active_on_action_list ? <StatusCircle green text="Не прихована"/> :
                                                    <StatusCircle red text="Прихована"/>}
                                            </span>
                                        </td>
                                    </TableListViewRow>
                                ))
                                : (
                                    <TableListViewRow disabled>
                                        <td colSpan={7} style={{paddingTop: '22px'}}>Немає Сортування Акцій</td>
                                    </TableListViewRow>
                                )
                        ))}
                    </tbody>
                </TableListViewTable>
            </div>
        </>
    );
};

export default SortSpecOffersList;
