import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import { getData, getFiltersData, remove } from "./api";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import ModalBrands from "./Modal/ModalBrands";

const BrandsList = () => {
    const [activeModal, setActiveModal] = useState(false);
    const [activeId, setActiveId] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        urlParams,
        setRest,
        pushUrlParams,
        filtersData,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Brand list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.last_page}
                                        current={+rest?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[{ title: 'ID', attr: { style: { width: '90px' } } }, 'Назва']}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {type: 'text', name: 'name'},
                            {},
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (<TableListViewRowLoader cols={2}/>)
                    : (rest && (
                        rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e?.id}
                                    key={`brand-item-${e?.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: "Бренд видалено",
                                    }}
                                    editLink={`/app/brand/update/${e?.id}`}
                                    permissionRemove='admin.brand.destroy'
                                    permissionEdit='admin.brand.edit'
                                    deleteButton={true}
                                    setActiveModal={setActiveModal}
                                    setActiveId={setActiveId}
                                    tagA={false}
                                    title={`Видалити бренд “${e?.name}”?`}
                                    additionalStyles={{
                                        container: { minWidth: '636px' },
                                        header: { display: 'flex', justifyContent: 'center' }
                                    }}
                                >
                                    <td>
                                        {checkPermission('admin.brand.edit')
                                            ? <Link to={`/app/brand/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                  <span>
                                    {e?.name || '---'}
                                  </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={2}>Немає брендів</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
            {activeModal && (
                <ModalBrands
                    title="Редагувати бренд"
                    active={activeModal}
                    setActive={setActiveModal}
                    editId={activeId}
                />
            )}
        </div>
    );
};

export default BrandsList;
