import { parseAll } from "../../lib/api/_parseApi";
import { getDomains } from "../../lib/api/domains";

import {
    createAdditionalService,
    editAdditionalService,
    getAdditionalServiceEdit,
    getAdditionalServices,
    removeAdditionalService,
} from "../../lib/api/additional_services";
import { refreshToken } from "../../lib/crud/refreshToken";
import { getCatAll } from "../../lib/api/categories";

export const getData = (additionalServiceID) => Promise.allSettled([getDomains(), additionalServiceID
&& getAdditionalServiceEdit(additionalServiceID), getAdditionalServices({ per_page: 10000 }), getCatAll()])
    .then(async(res) => {
        const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

        if(findStatus401) {
            return await refreshToken(() => window.location.reload())
        }

        return ({
            domains: res[0]?.value?.data,
            fields: additionalServiceID && res[1]?.value?.data,
            additionalServices: res[2]?.value?.data?.data,
            categoryAll: res[3]?.value?.data,
        })
    });

export const create = createAdditionalService;
export const edit = editAdditionalService;
export const remove = removeAdditionalService;

export const parseDataSend = (data, bankId) => {
    let d = { ...data };
    const isUpdate = typeof bankId !== 'undefined';
    if(!d?.categories?.length) {
        d.categories = [];
    }

    if(d?.categories_prices?.length) {
        const updatedCategories = d?.categories.map(categoryObj => {
            const match = d?.categories_prices.find(priceObj => priceObj.category_id === categoryObj.category_id);
            return match ? { ...categoryObj, price_type: match.price_type } : categoryObj;
        });

        d?.categories_prices.forEach(priceObj => {
            if (!updatedCategories.some(categoryObj => categoryObj.category_id === priceObj.category_id)) {
                updatedCategories.push(priceObj);
            }
        });

        d.categories = updatedCategories;
    }

    if(!d?.products_turn_off?.length) {
        d.products_turn_off = [];
    }

    // if (d?.categories?.length) {
    //   d.categories = d?.categories?.split(',')
    // }

    if(!d?.products_turn_on?.length) {
        d.products_turn_on = [];
    }

    // d.products_turn_on = d?.products_turn_on?.map((item, pos) => {
    //   return {...item }
    // });

    d.products_turn_off = d?.products_turn_off?.filter((item, pos) => {
        return d?.products_turn_off?.indexOf(item) === pos;
    });

    if(!!d?.delivery_ids?.length) {
        d['delivery_ids'] = d?.delivery_ids?.filter(item => typeof item.delivery_type === "string").map(item => +item.delivery_type);
    }
console.log(d)
    d = parseAll(d, !isUpdate, []);

    return d;
};

export const parseDataGet = (data) => {
    const d = { ...data };
    const toStr = [];

    toStr.forEach((p) => {
        d[p] = `${d[p]}`;
    });

    return d;
};
