const parseCategoriesActionNodes = (data) => {
    const parseCategory = []

    const parseCategoriesAction = (nodes) => {
        if (!!nodes.children?.length) {
            nodes.children.map((node) => parseCategoriesAction(node))
        }

        return parseCategory.push({
            id: nodes.id,
            title: nodes.title
        });
    }

    parseCategoriesAction(data?.[0])

    return parseCategory;
}

export default parseCategoriesActionNodes;