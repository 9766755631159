import {
    SORT_PRODUCTS_GET,
    SORT_PRODUCT_DELETE,
    SORT_PRODUCT_TO_POST,
    SORT_PRODUCTS_REFRESH,
    SORT_PRODUCTS_UPDATE,
    SORT_BANNERS_TYPES_GET,
    SORT_BANNER_CATALOG_GET,
    SORT_BANNER_CATALOG_REFRESH,
    SORT_BANNER_CATALOG_UPDATE,
    SORT_BANNER_CATALOG_TO_POST,
    SORT_BANNER_CATALOG_DELETE,
    SORT_BANNER_CATALOG_HORIZONTAL_GET,
    SORT_BANNER_CATALOG_HORIZONTAL_REFRESH,
    SORT_BANNER_CATALOG_HORIZONTAL_UPDATE,
    SORT_BANNER_CATALOG_HORIZONTAL_TO_POST,
    SORT_BANNER_CATALOG_HORIZONTAL_DELETE,
    SORT_BANNER_HEADER_GET,
    SORT_BANNER_HEADER_REFRESH,
    SORT_BANNER_HEADER_UPDATE,
    SORT_BANNER_HEADER_TO_POST,
    SORT_BANNER_HEADER_DELETE,
    SORT_BANNER_MENU_DELETE,
    SORT_BANNER_MENU_TO_POST,
    SORT_BANNER_MENU_UPDATE,
    SORT_BANNER_MENU_REFRESH,
    SORT_BANNER_MENU_GET,
    SORT_BANNER_MAIN_PAGE_DELETE,
    SORT_BANNER_MAIN_PAGE_TO_POST,
    SORT_BANNER_MAIN_PAGE_UPDATE,
    SORT_BANNER_MAIN_PAGE_REFRESH,
    SORT_BANNER_MAIN_PAGE_GET,
    SORT_SPECIAL_OFFERS_GET,
    SORT_SPECIAL_OFFERS_REFRESH,
    SORT_SPECIAL_OFFERS_UPDATE,
    SORT_SPECIAL_OFFERS_TO_POST,
    SORT_SPECIAL_OFFERS_DELETE,
    SORT_CATEGORIES_GET,
    SORT_CATEGORIES_DELETE,
    SORT_CATEGORIES_TO_POST,
    SORT_CATEGORIES_UPDATE,
    SORT_CATEGORIES_REFRESH,
    EDIT_SORT_PRODUCTS_POSITION,
    EDIT_SORT_CATEGORIES_POSITION,
    EDIT_BANNER_POSITION,
    EDIT_SORT_BANNER_HEADER_POSITION,
    SORT_BANNER_MAIN_PAGE_POSITION,
    SORT_BANNER_CATALOG_PAGE_POSITION,
    SORT_BANNER_CATALOG_HORIZONTAL_PAGE_POSITION,
    BANK_SELECTION_POSITION,
    BANK_SELECTION_UPDATE, BANK_SELECTION_REFRESH
} from "../../const/api";
import { LIST_PER_PAGE } from "../../const/view";
import fetchAx from "../fetchAx";

/* products */

export const getSortProducts = (params) => fetchAx({
    url: SORT_PRODUCTS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.l,
        page: 1,
        ...params
    }
});

export const refreshSortProducts = (params) => fetchAx({
    url: SORT_PRODUCTS_REFRESH,
    method: 'POST',
    params
});

export const updateSortProducts = (data) => fetchAx({
    url: SORT_PRODUCTS_UPDATE,
    method: 'PUT',
    data
});

export const postSortProductTo = (id, to, params) => fetchAx({
    url: SORT_PRODUCT_TO_POST.join(id) + to,
    method: 'POST',
    params
});

export const removeSortProduct = (params) => (id) => fetchAx({
    url: SORT_PRODUCT_DELETE.join(id),
    method: 'DELETE',
    params
});

/* banner-catalog */

export const getSortBannerCatalog = (params) => fetchAx({
    url: SORT_BANNER_CATALOG_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.l,
        page: 1,
        ...params
    }
});

export const refreshSortBannerCatalog = (params) => fetchAx({
    url: SORT_BANNER_CATALOG_REFRESH,
    method: 'POST',
    params
});

export const updateSortBannerCatalog = (data) => fetchAx({
    url: SORT_BANNER_CATALOG_UPDATE,
    method: 'PUT',
    data
});

export const postSortBannerCatalogTo = (id, to, params) => fetchAx({
    url: SORT_BANNER_CATALOG_TO_POST.join(id) + to,
    method: 'POST',
    params
});

export const removeSortBannerCatalog = (params) => (id) => fetchAx({
    url: SORT_BANNER_CATALOG_DELETE.join(id),
    method: 'DELETE',
    params
});

/* banner-catalog-horizontal */

export const getSortBannerCatalogHorizontal = (params) => fetchAx({
    url: SORT_BANNER_CATALOG_HORIZONTAL_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.l,
        page: 1,
        ...params
    }
});

export const refreshSortBannerCatalogHorizontal = (params) => fetchAx({
    url: SORT_BANNER_CATALOG_HORIZONTAL_REFRESH,
    method: 'POST',
    params
});

export const updateSortBannerCatalogHorizontal = (data) => fetchAx({
    url: SORT_BANNER_CATALOG_HORIZONTAL_UPDATE,
    method: 'PUT',
    data
});

export const postSortBannerCatalogHorizontalTo = (id, to, params) => fetchAx({
    url: SORT_BANNER_CATALOG_HORIZONTAL_TO_POST.join(id) + to,
    method: 'POST',
    params
});

export const removeSortBannerCatalogHorizontal = (params) => (id) => fetchAx({
    url: SORT_BANNER_CATALOG_HORIZONTAL_DELETE.join(id),
    method: 'DELETE',
    params
});

/* banner-catalog-header */

export const getSortBannerHeader = (params) => fetchAx({
    url: SORT_BANNER_HEADER_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.l,
        page: 1,
        ...params
    }
});

export const refreshSortBannerHeader = (params) => fetchAx({
    url: SORT_BANNER_HEADER_REFRESH,
    method: 'POST',
    params
});

export const updateSortBannerHeader = (data) => fetchAx({
    url: SORT_BANNER_HEADER_UPDATE,
    method: 'PUT',
    data
});

export const postSortBannerHeader = (id, to, params) => fetchAx({
    url: SORT_BANNER_HEADER_TO_POST.join(id) + to,
    method: 'POST',
    params
});

export const removeSortBannerHeader = (params) => (id) => fetchAx({
    url: SORT_BANNER_HEADER_DELETE.join(id),
    method: 'DELETE',
    params
});

/* banner-menu */

export const getSortBannerMenu = (params) => fetchAx({
    url: SORT_BANNER_MENU_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.l,
        page: 1,
        ...params
    }
});

export const refreshSortBannerMenu = (params) => fetchAx({
    url: SORT_BANNER_MENU_REFRESH,
    method: 'POST',
    params
});

export const updateSortBannerMenu = (data) => fetchAx({
    url: SORT_BANNER_MENU_UPDATE,
    method: 'PUT',
    data
});

export const postSortBannerMenu = (id, to, params) => fetchAx({
    url: SORT_BANNER_MENU_TO_POST.join(id) + to,
    method: 'POST',
    params
});

export const removeSortBannerMenu = (params) => (id) => fetchAx({
    url: SORT_BANNER_MENU_DELETE.join(id),
    method: 'DELETE',
    params
});

/* banner-catalog-main-page */

export const getSortBannerMainPage = (params) => fetchAx({
    url: SORT_BANNER_MAIN_PAGE_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.l,
        page: 1,
        ...params
    }
});

export const refreshSortBannerMainPage = (params) => fetchAx({
    url: SORT_BANNER_MAIN_PAGE_REFRESH,
    method: 'POST',
    params
});

export const updateSortBannerMainPage = (data) => fetchAx({
    url: SORT_BANNER_MAIN_PAGE_UPDATE,
    method: 'PUT',
    data
});

export const postSortBannerMainPage = (id, to, params) => fetchAx({
    url: SORT_BANNER_MAIN_PAGE_TO_POST.join(id) + to,
    method: 'POST',
    params
});

export const removeSortBannerMainPage = (params) => (id) => fetchAx({
    url: SORT_BANNER_MAIN_PAGE_DELETE.join(id),
    method: 'DELETE',
    params
});

/* banners/types */

export const getSortBannersTypes = (params) => fetchAx({
    url: SORT_BANNERS_TYPES_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.l,
        page: 1,
        ...params
    }
});

/* special-offers */

export const getSortSpecialOffers = (params) => fetchAx({
    url: SORT_SPECIAL_OFFERS_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.l,
        page: 1,
        ...params
    }
});

export const refreshSortSpecialOffers = (params) => fetchAx({
    url: SORT_SPECIAL_OFFERS_REFRESH,
    method: 'POST',
    params
});

export const updateSortSpecialOffers = (data) => fetchAx({
    url: SORT_SPECIAL_OFFERS_UPDATE,
    method: 'PUT',
    data
});

export const editSortSpecialOffers = (params) => (id, to) => fetchAx({
    url: SORT_SPECIAL_OFFERS_TO_POST.join(id) + to,
    method: 'POST',
    params
});

export const removeSortSpecialOffers = (params) => (id) => fetchAx({
    url: SORT_SPECIAL_OFFERS_DELETE.join(id),
    method: 'DELETE',
    params
});

/* categories */

export const getSortCategories = (params) => fetchAx({
    url: SORT_CATEGORIES_GET,
    method: 'GET',
    params: {
        per_page: LIST_PER_PAGE.l,
        page: 1,
        ...params
    }
});

export const refreshSortCategories = (params) => fetchAx({
    url: SORT_CATEGORIES_REFRESH,
    method: 'POST',
    params
});

export const updateSortCategories = (data) => fetchAx({
    url: SORT_CATEGORIES_UPDATE,
    method: 'PUT',
    data
});

export const postSortCategories = (id, to, params) => fetchAx({
    url: SORT_CATEGORIES_TO_POST.join(id) + to,
    method: 'POST',
    params
});

export const removeSortCategories = (params) => (id) => fetchAx({
    url: SORT_CATEGORIES_DELETE.join(id),
    method: 'DELETE',
    params
});

export const editSortProducts = (params) => (id, position) => fetchAx({
    url: EDIT_SORT_PRODUCTS_POSITION.join(id) + position,
    method: 'PUT',
    params
})

export const editSortCategories = (params) => (id, position) => fetchAx({
    url: EDIT_SORT_CATEGORIES_POSITION.join(id) + position,
    method: 'PUT',
    params
})

export const editSortBannerMenu = (params) => (id, position) => fetchAx({
    url: EDIT_BANNER_POSITION.join(id) + position,
    method: 'POST',
    params
})

export const editSortBannerHeader = (params) => (id, position) => fetchAx({
    url: EDIT_SORT_BANNER_HEADER_POSITION.join(id) + position,
    method: 'POST',
    params
})

export const editSortBannerMainPage = (params) => (id, position) => fetchAx({
    url: SORT_BANNER_MAIN_PAGE_POSITION.join(id) + position,
    method: 'POST',
    params
})

export const editSortBannerCatalog = (params) => (id, position) => fetchAx({
    url: SORT_BANNER_CATALOG_PAGE_POSITION.join(id) + position,
    method: 'POST',
    params
})

export const editSortBannerCatalogHorizontal = (params) => (id, position) => fetchAx({
    url: SORT_BANNER_CATALOG_HORIZONTAL_PAGE_POSITION.join(id) + position,
    method: 'POST',
    params
})

export const editSortBankSelections = (params) => (id, position) => fetchAx({
    url: BANK_SELECTION_POSITION.join(id) + position,
    method: 'POST',
    params
})

export const updateSortBankSelections = (data) => fetchAx({
    url: BANK_SELECTION_UPDATE,
    method: 'PUT',
    data
});

export const refreshSortBankSelections = (params) => fetchAx({
    url: BANK_SELECTION_REFRESH,
    method: 'POST',
    params
});