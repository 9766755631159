import {
    SUBSCRIBERS_GET,
    SUBSCRIBER_CREATE,
    SUBSCRIBER_DELETE,
    SUBSCRIBER_GET_EDIT,
    SUBSCRIBER_EDIT, SUBSCRIBERS_TYPES_GET,
} from '../../const/api';
import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getSubscribers = (params) => fetchAx({
  url: SUBSCRIBERS_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    page: 1,
    ...params,
  },
});

export const createSubscriber = (data) => fetchAx({
  url: SUBSCRIBER_CREATE,
  method: 'POST',
  data,
});

export const getSubscriberEdit = (id) => fetchAx({
  url: SUBSCRIBER_GET_EDIT.join(id),
  method: 'GET',
});

export const editSubscriber = (id, data) => fetchAx({
  url: SUBSCRIBER_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeSubscriber = (id) => fetchAx({
  url: SUBSCRIBER_DELETE.join(id),
  method: 'DELETE',
});